import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaCNPJ, validaData } from '../../../../../util/Validacoes';
import { InputPadrao, InputTelefone, InputDatePicker, InputDatePickerMultiplo, InputTimePicker, TextArea } from '../../../../../components/Inputs'
import { mostrarToast } from '../../../../../components/Toasts'
import { BotaoPadrao } from '../../../../../components/Botoes'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { salvar, get, validar } from '../../../../../util/AcessoApi'
import { getUsuario } from '../../../../../util/Configuracoes'

import axios from 'axios'
import { mensagens, ajustarDadosTelefone, abreviarNomeSocio, useWindowSize, useInterval, sortTable } from '../../../../../util/Utilitarios'
import moment from "moment"
import 'moment/locale/pt-br'
import { InputMunicipio, InputSocioAtivos, InputPeriodoFerias } from '../../../../../components/InputsAutoComplete'
import { ModalDuasOpcoes, ModalCalendar } from '../../../../../components/Modais'
import InfiniteCalendar from 'react-infinite-calendar';
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../../components/Headers'
import Atalho from '../../../../../util/Atalho'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CadastroModalMercado } from '../../mercado/pages/Cadastro'
import { TabContainer } from 'react-bootstrap';
import { LoadRelatorio } from '../../../../../components/Loading'
import { LinkExclusaoTabela, LinkAlteracaoTabela } from '../../../../../components/Acoes'
import { ordenarMultiplo } from '../../../../../components/Ordenamentos'

export default props => {
    const [width, height] = useWindowSize();
    const location = useLocation()
    const [direitoFolga, setDireitoFolga] = useState({})

    let { id } = useParams()



    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [showModalMercado, setShowModalMercado] = useState(false)
    const [dadosResumo, setDadosResumo] = useState({})
    const [socioEscolhido, setSocioEscolhido] = useState({})
    const [dataReferencia, setDataReferencia] = useState(location?.state?.item ? location?.state?.item?.data_formatada : new moment().format('DD/MM/YYYY'))
    const [dataAtual, setDataAtual] = useState(new moment().format('DD/MM/YYYY'))
    const [dadosAusenciasDia, setDadosAusenciasDia] = useState({})
    const [dataDadosAusencia, setDataDadosAusencia] = useState()
    const [carregandoTabelasResumoSocio, setCarregandoTabelasResumoSocio] = useState(false)
    const [carregandoTabelaAusencia, setCarregandoTabelaAusencia] = useState(false)

    const [alterarEmTela, setAlterarEmTela] = useState(false)
    const [idAlteracaoEmTela, setIdAlteracaoEmTela] = useState(-1)
    const [idAlteracaoMercado, setIdAlteracaoMercado] = useState(-1)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [msgModal, setMsgModal] = useState(<></>)
    const [esconderPodeContinuar, setEsconderPodeContinuar] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [showModalInterAgenda, setShowModalInterAgenda] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState(<></>)

    const manter = ['data', 'socio', 'periodo', 'horario_inicial', 'horario_final', 'dias', 'observacao']

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className='link-active' to={'/administracao/ferias/marcacao/consulta'}>Cadastro Marcação de Férias</Link>,
    ]


    useEffect(() => {
        if (location?.state?.item) {
            setTimeout(() => {
                let locationDireitoFolga = location.state.item
                setDireitoFolga(location?.state?.item)

                if (locationDireitoFolga?.data_formatada) {
                    setDataReferencia(location?.state?.item?.data_formatada)
                    setDataDadosAusencia(location?.state?.item?.data_formatada)

                }

                if (!id) {
                    if (locationDireitoFolga?.data_formatada) {
                        setValoresIniciais({
                            id: "",
                            data: [locationDireitoFolga.data_formatada],
                            socio: locationDireitoFolga && locationDireitoFolga.socio ? locationDireitoFolga.socio : "",
                            periodo: null,
                            horario_inicial: "",
                            horario_final: "",
                        })
                    }
                    else {
                        initialValuesFunc(locationDireitoFolga);
                    }
                }
            }, 100);
        } else if (id) {
            Promise.resolve(get('/administracao/ferias/marcacao/' + id)).then(function (obj) {
                if (obj.length === 0)
                    history.push('/administracao/ferias/marcacao/consulta')


                delete obj.fone
                obj.fone = ""
                setDataDadosAusencia(obj.data)
                setValoresIniciais(obj)
                setSocioEscolhido(obj.socio)
                // refreshTabelas();
            }).catch((e) => {
            });

        } else {
            initialValuesFunc({})


        }

    }, [id, location])

    setTimeout(() => {
        let focusInput = document.getElementsByTagName('input')[1]
        if (focusInput)
            focusInput.focus()

    }, 500);

    const initialValuesFunc = (item) => {
        setValoresIniciais({
            id: "",
            data: [new moment().format('DD/MM/YYYY')],
            socio: item && item.socio ? item.socio : "",
            periodo: null,
            horario_inicial: "",
            horario_final: "",
        })

        setDataDadosAusencia(new moment().format('DD/MM/YYYY'))
    }

    useEffect(() => {
        if (dataReferencia == '' || dataReferencia == null)
            setDataReferencia(new moment().format('DD/MM/YYYY'))
        else
            setDataDadosAusencia(dataReferencia)
    }, [dataReferencia])



    const [validation, setValidation] = useState(Yup.object({
        data: Yup.string()
            .required().nullable(),
        socio: Yup.string()
            .required().nullable(),
        periodo: Yup.object()
            .nullable(),
        horario_inicial: Yup.string()
            .required().nullable(),
        horario_final: Yup.string()
            .required().nullable(),
        observacao: Yup.string()
            .nullable()
    }))

    const { history } = props

    const navigationData = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ paddingTop: '0px' }}>Férias e Ocorrências do Dia</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginLeft: '20px', marginRight: '5px' }}><button type="button" className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => { setDataReferencia(new moment(dataReferencia, 'DD/MM/YYYY').subtract(1, 'd').format('DD/MM/YYYY')); }}></button></div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '5px' }}>
                                <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
                                    <InfiniteCalendar className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                                        locale={{
                                            locale: require('date-fns/locale/pt'),
                                            blank: 'Selecione uma data',
                                            headerFormat: 'ddd, MMM Do',
                                            weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                            todayLabel: {
                                                long: 'Hoje',
                                                short: 'Hoje'
                                            },
                                            weekStartsOn: 0,
                                        }}
                                        selected={dataReferencia == null || dataReferencia == "" ? new moment().toDate() : new moment(dataReferencia, 'DD/MM/YYYY').toDate()}
                                        onSelect={value => { setDataReferencia(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear()); setShowCalendar(!showCalendar); }} height={height - 150}
                                        width={width < 501 ? width : 403}
                                    />
                                </ModalCalendar>
                                <div><span style={{ paddingLeft: '15px', paddingRight: '15px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowCalendar(!showCalendar)}>{dataReferencia}</span></div>
                                <span style={{ fontSize: '10px' }}> {moment(dataReferencia, 'DD/MM/YYYY').format('dddd')}</span>
                            </div>
                        </div>
                        <div style={{ marginRight: '20px', marginLeft: '5px' }}><button type="button" className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => { setDataReferencia(new moment(dataReferencia, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')); }}></button></div>
                    </div>
                </div>
            </>

        )
    }


    const cancelarAlteracao = () => {
        setAlterarEmTela(false);
        setarValoresVazios();
        // setTimeout(() => {
        //     document.getElementById('input-data-marcacao').focus();
        // }, 100);
    }


    const refAtualizacao = useRef(null)
    const [totalMarcacoes, setTotalMarcacoes] = useState(0)
    const [saldo, setSaldo] = useState(0)
    const [propsFormikGlobal, setPropsFormikGlobal] = useState(useRef(null))

    useEffect(() => {
        getDadosResumo();
    }, [dataAtual, socioEscolhido])


    useEffect(() => {
        if (dadosResumo.marcacoes) {
            let calcMarcacoes = dadosResumo.marcacoes.length > 0 ? dadosResumo.marcacoes.map(x => x.hora_dif).reduce((total, corrente) => total + corrente) / 12 : 0;
            setTotalMarcacoes(calcMarcacoes)
            setSaldo((dadosResumo.dias_direito.dias - calcMarcacoes) + dadosResumo.mercado.credito + dadosResumo.mercado.debito)
        }
    }, [dadosResumo])
    const getDadosResumo = async () => {
        setUltimoUpdateTabelas(new moment())
        setCarregandoTabelasResumoSocio(false);

        await Promise.resolve(get(`/administracao/ferias/obterdadosresumo`, { data: dataDadosAusencia, socioId: socioEscolhido.value })).then(obj => {
            // if (obj.socio) {
            setDadosResumo(obj)
            // }
            setCarregandoTabelasResumoSocio(false); updateMd5DadosResumo();
        })
    }

    const [md5DadosAusencia, setMd5DadosAusencia] = useState('')
    const getDadosAusenciaAutomatico = () => {
        Promise.resolve(get(`/administracao/ferias/obterdadosausenciaMD5`, { data: dataDadosAusencia })).then(obj => {
            if (obj && obj.md5 && md5DadosAusencia != obj.md5) {
                if (md5DadosAusencia == "") {
                    setMd5DadosAusencia(obj.md5)
                    return;
                }
                let now = new moment();
                if (now.diff(ultimoUpdateTabelas, 'seconds') > 6) {
                    setMd5DadosAusencia(obj.md5)
                    getDadosAusencia();
                }
                else {
                    //setMd5DadosAusencia(obj.md5)
                }

            }
        })
    }
    const [ultimoUpdateTabelas, setUltimoUpdateTabelas] = useState(new moment())
    const [md5DadosResumo, setMd5DadosResumo] = useState('')
    const getDadosResumoAutomatico = () => {
        Promise.resolve(get(`/administracao/ferias/obterdadosresumoMD5`, { data: dataReferencia, socioId: socioEscolhido.value })).then(obj => {
            if (obj && obj.md5 && md5DadosResumo != obj.md5) {
                if (md5DadosResumo == "") {
                    setMd5DadosResumo(obj.md5)
                    return;
                }
                let now = new moment();
                if (now.diff(ultimoUpdateTabelas, 'seconds') > 6) {
                    setMd5DadosResumo(obj.md5)
                    getDadosResumo();
                }
                else {
                    //setMd5DadosResumo(obj.md5)
                }
            }
        })
    }

    const updateMd5DadosAusencia = () => {
        try {
            Promise.resolve(get(`/administracao/ferias/obterdadosausenciaMD5`, { data: dataDadosAusencia })).then(obj => {
                if (obj && obj.md5) {
                    setMd5DadosAusencia(obj.md5)
                }
            })
        }
        catch (err) {
            //console.log(err)
        }

    }

    const updateMd5DadosResumo = () => {
        try {
            Promise.resolve(get(`/administracao/ferias/obterdadosresumoMD5`, { data: dataReferencia, socioId: socioEscolhido.value })).then(obj => {
                if (obj && obj.md5) {
                    setMd5DadosResumo(obj.md5)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }


    useInterval(() => {
        getDadosResumoAutomatico();
        getDadosAusenciaAutomatico();
    }, 3000)

    const validarSubmit = (values, propsFormik, nValidacao) => {
        switch (nValidacao) {
            case 1:
                getValidacao(values, propsFormik)
                break;
            case 2:
                ValidarInterposicaoAgenda(values, propsFormik)
                break;
            default:
                getValidacao(values, propsFormik)
                break;
        }
    }

    const ValidarInterposicaoAgenda = (values, propsFormik) => {
        validar('/administracao/validarOcorrenciaAgendamento', { modulo: 60, ...values }, () => funcaoAposSim(values, propsFormik), (e) => {
            setShowModalInterAgenda(true)
            setIsSubmit(false)
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: e.response.data.alertas[0].replace(/"/g, '') }} /></>)
        })
    }

    const getDadosAusencia = () => {
        setCarregandoTabelaAusencia(false);
        setUltimoUpdateTabelas(new moment())
        if (dataDadosAusencia)
            Promise.resolve(get(`/administracao/ferias/obterausenciasdia`, { data: dataDadosAusencia })).then(obj => {
                setDadosAusenciasDia(obj); setCarregandoTabelaAusencia(false);
                updateMd5DadosAusencia();
            })
    }

    const getValidacao = (values, propsFormik) => {
        setIsSubmit(true)
        Promise.resolve(get(`/administracao/ferias/obtervalidacoesmarcacoes`, { ...values, saldo_calculado: saldo, usuario: getUsuario() })).then(obj => {
            if (obj && obj.length > 0) {
                setIsSubmit(false);
                setShowModalValidacao(obj.length > 0);
                setMsgModal(obj.map(x => <p>- {x.msg}</p>));
                setEsconderPodeContinuar(obj.filter(x => x.tipo == "bloqueio").length > 0)
            } else {
                validarSubmit(values, propsFormik, 2)
            }
        })
    }

    useEffect(() => {
        getDadosAusencia();
    }, [dataDadosAusencia])

    useEffect(() => {
        setSocioEscolhido(valoresIniciais.socio == null ? {} : valoresIniciais.socio)
    }, [valoresIniciais])




    const refreshTabelas = () => {
        getDadosAusencia();
        getDadosResumo();
    }


    useEffect(() => {
    }, [])

    const Submit = (values, propsFormik) => {

        getValidacao(values, propsFormik)
    }

    const funcaoAposSim = (values, propsFormik) => {
        setShowModalValidacao(false);
        setIsSubmit(true)
        if (direitoFolga && direitoFolga.id)
            values.direito_folga_id = direitoFolga.id
        salvar(values, '/administracao/ferias/marcacao', (ret) => {
            mostrarToast('sucesso', mensagens.sucesso)
            if (alterarEmTela) {
                setarValoresVaziosAlteracaoEmTela(undefined, propsFormik);
                propsFormik.resetForm({})
                setIsSubmit(false)
                setAlterarEmTela(false)
                refreshTabelas();
            }
            else if (salvarENovo) {
                propsFormik.resetForm({})
                manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                setIsSubmit(false)
                setSalvarENovo(false)
                refreshTabelas();
            }
            else
                history.push(direitoFolga.id ? '/administracao/direitofolga/consulta' : '/administracao/ferias/marcacao/consulta')
        }, err => {
            mostrarToast('erro', mensagens.erro)
            setIsSubmit(false)
        })
    }

    function handleChangePeriodo(option, propsFormik) {
        propsFormik.setFieldValue("periodo", option);
        propsFormik.setFieldValue("horario_inicial", option && option.horario_inicial);
        propsFormik.setFieldValue("horario_final", option && option.horario_final);
        propsFormik.setFieldValue("dias", option && option.peso);
    }

    function handleChangeSocio(option, propsFormik) {
        propsFormik.setFieldValue("socio", option);
        setSocioEscolhido(option == null ? {} : option)
    }


    useEffect(() => {
        if (idAlteracaoEmTela > 0) {
            setarValoresVazios(true);
            Promise.resolve(get('/administracao/ferias/marcacao/' + idAlteracaoEmTela)).then(function (obj) {
                document.getElementById('input-data-marcacao').focus();

                setValoresIniciais(obj)
            }).catch((e) => {
                console.log(e, 'erro')
            });
        }
    }, [idAlteracaoEmTela])

    const setarValoresVazios = (naoZerarAlteracaoEmTela) => {
        setValoresIniciais({
            id: "",
            data: [new moment().format('DD/MM/YYYY')],
            socio: "",
            periodo: null,
            horario_inicial: "",
            horario_final: "",
        })

        setTimeout(_ => {
            if (!naoZerarAlteracaoEmTela)
                setIdAlteracaoEmTela(0)
        }, 100)
    }

    const setarValoresVaziosAlteracaoEmTela = (naoZerarAlteracaoEmTela, propsFormik) => {
        setValoresIniciais({
            id: "",
            data: [new moment().format('DD/MM/YYYY')],
            socio: propsFormik.values && propsFormik.values.socio ? propsFormik.values.socio : "",
            periodo: null,
            horario_inicial: "",
            horario_final: "",
        })

        setTimeout(_ => {
            if (!naoZerarAlteracaoEmTela)
                setIdAlteracaoEmTela(0)
        }, 100)
    }



    if (valoresIniciais.data == undefined)
        return <></>
    else
        return (
            <>

                {!props.modal && <HeaderCadastro titulo={"Cadastro Marcação"} link={links} />}
                <CadastroModalMercado abrirModal={showModalMercado} modal idAlteracao={idAlteracaoMercado}
                    setAbrirModal={setShowModalMercado} funcaoAposSalvar={() => { refreshTabelas() }} dataAtual={dataAtual} socioAtual={socioEscolhido} />
                <Formik
                    initialValues={valoresIniciais}
                    enableReinitialize={true}
                    validationSchema={validation}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <Form>
                            {!propsFormikGlobal.errors && setPropsFormikGlobal(useRef(propsFormik))}
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik.values, propsFormik)} funcaoOpcao1={() => { setIsSubmit(false); setShowModalValidacao(false); }}
                                esconderFechar mensagem={msgModal} esconderOpcao2={esconderPodeContinuar}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                            <ModalDuasOpcoes showModal={showModalInterAgenda} setShowModal={setShowModalInterAgenda}
                                funcaoOpcao2={() => { funcaoAposSim(propsFormik.values, propsFormik); setShowModalInterAgenda(false) }}
                                funcaoOpcao1={() => { setShowModalInterAgenda(false) }}
                                esconderFechar mensagem={<>{<>{'Há Agenda(s)/Escala(s):'} <br /><br /></>} {msgValidacao}<br /></>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                            <div className={props.modal && props.modal || ' mb-20'}>

                                <div className={props.modal || ''}>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-12">
                                            <div className="card b-radius-10 ">
                                                <div className="row card-body ">
                                                    <div className="col-12">
                                                        <div className="row" >
                                                            <InputPadrao tamanho="col-0" label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                                                            <>
                                                                {id > 0 || idAlteracaoEmTela > 0 ?
                                                                    <InputDatePicker id={'input-data-marcacao'} navigation tamanho={width < 1800 ? 'col-lg-12' : 'col-lg-4 col-md-6'} label="Data" name="data" type="text" obrigatorio="true" autoFocus
                                                                        setarValor={value => {
                                                                            propsFormik.setFieldValue("data", value);
                                                                            setDataReferencia(value);
                                                                            setDataAtual(value);
                                                                            setDataDadosAusencia(value)

                                                                        }} onChange={(data) => setDataReferencia(data)} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true}
                                                                    />
                                                                    :
                                                                    <InputDatePickerMultiplo id={'input-data-marcacao'} propsFormik={propsFormik} navigation tamanho={width < 1800 ? 'col-lg-12' : 'col-lg-6 col-md-6'} label="Data" name="data" type="text" obrigatorio="true" autoFocus
                                                                        onChange={(data) => {
                                                                            //propsFormik.setFieldValue("data", data);
                                                                            if (data[0])
                                                                                setDataReferencia(data[0] || '');
                                                                            setDataAtual(data[0] || '');
                                                                            setDataDadosAusencia(data[0])
                                                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true}
                                                                    />
                                                                }
                                                            </>
                                                            <InputSocioAtivos label="Sócio" name="socio" propsFormik={propsFormik} tamanho={width < 1800 ? 'col-lg-12' : 'col-xl-6 col-md-6'} obrigatorio
                                                                classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} onChangeValue={option => { handleChangeSocio(option, propsFormik) }} />
                                                        </div>
                                                        <div className="row">
                                                            <InputPeriodoFerias obrigatorio label="Período" name="periodo" propsFormik={propsFormik} tamanho={width < 1800 ? 'col-lg-12' : 'col-xl-6 col-md-6'} onChangeValue={option => { handleChangePeriodo(option, propsFormik) }}
                                                                classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />
                                                            <div style={{ display: 'none' }} >
                                                                <InputTimePicker tamanho={width < 1800 ? 'col-lg-6' : 'col-xl-3 col-md-5'} label="Horário Inicial" mascara="11:11" name="horario_inicial" type="text" placeholder="Horário Inicial"
                                                                    obrigatorio={true} classeTooltip={'tooltip-input-modal'} setarValor={value => propsFormik.setFieldValue("horario_inicial", value)} />
                                                                <InputTimePicker tamanho={width < 1800 ? 'col-lg-6' : 'col-xl-3 col-md-5'} style={{ display: 'none' }} label="Horário Final" mascara="11:11" name="horario_final" type="text" placeholder="Horário Inicial"
                                                                    obrigatorio={true} classeTooltip={'tooltip-input-modal'} setarValor={value => propsFormik.setFieldValue("horario_final", value)} />
                                                            </div>
                                                            <TextArea rows={1} label='Observação' name='observacao' type='text' palcelhoder='Observação' tamanho='col-xl-6 col-md-6' />
                                                            <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='separator mb-3'></div>
                                                <div className='row justify-content-between container-botoes mb-3 group-salvar-novo'>
                                                    <div className='float-left' style={{ paddingLeft: '27px' }}>
                                                        {
                                                            <>
                                                                <a onClick={() => { setIdAlteracaoMercado(0); setShowModalMercado(!showModalMercado); }} href='#'><span className="icon-Add-Cart"></span> Mercado de férias</a>
                                                                <Link style={{ color: '#007bff', paddingLeft: '27px' }} target="_blank" to={'/administracao/socio-ocorrencia/consulta'}><span className="icon-Barricade-2"></span> Ocorrências</Link>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className='float-right' style={{ paddingRight: '27px' }}>
                                                        {<BotaoPadrao texto={alterarEmTela ? 'Alterar' : 'Salvar'} disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />}
                                                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} style={{ display: alterarEmTela ? 'none' : 'inline' }} />}
                                                        {alterarEmTela ? <BotaoPadrao type='button' texto={'Cancelar'} disabled={isSubmit} click={_ => { cancelarAlteracao(); }} idTooltip={'cancelar'} /> : <></>}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className='card b-radius-10' style={{ zIndex: 1, minHeight: '458px', marginTop: '10px' }}>
                                                        <div className='card-body'>
                                                            <div class="mb-2 row-space-between"><div class="card-title">Resumo do sócio {dadosResumo.socio ? (<><strong>({socioEscolhido.label})</strong>{dadosResumo.periodo_gozo ? <span> no ano de {dadosResumo.periodo_gozo && dadosResumo.periodo_gozo.ano} </span> : <></>}</>) : <></>}</div></div>
                                                            <div className='dashboard-auditoria'>
                                                                <div className='resumo' style={{ padding: 5 }}>
                                                                    {dadosResumo.socio == undefined ? <></> :
                                                                        (
                                                                            <>
                                                                                <div className='row mb-2'>
                                                                                    <div className='col-3 bold '>Dias de direito: {parseFloat(dadosResumo.dias_direito.dias || 0).toFixed(2)}</div>
                                                                                    <div className='col-3 bold'>Dias mercado: {parseFloat(dadosResumo.mercado.credito + dadosResumo.mercado.debito).toFixed(2)}</div>
                                                                                    <div className='col-3 bold'>Total marcações: {parseFloat(totalMarcacoes).toFixed(2)}</div>
                                                                                    <div className='col-3 bold text-right'>Saldo: {parseFloat(saldo).toFixed(2)}</div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                                <Tabs style={{ backgroundColor: '#F8F8F8' }}>
                                                                    <TabList>
                                                                        <Tab><span className={"icone-tab icon-Yacht "}></span>Marcações</Tab>
                                                                        <Tab><span className={"icone-tab icon-Add-Cart "}></span> Mercado</Tab>
                                                                        <Tab><span className={"icone-tab icon-Barricade-2 "}></span> Ocorrências</Tab>
                                                                    </TabList>
                                                                    <TabPanel>
                                                                        <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '308px' }} ref={refAtualizacao}>
                                                                            <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel" id="tbl-marcacoes">
                                                                                <thead>
                                                                                    <th className="contador" onClick={() => sortTable('tbl-marcacoes', 0, 'numero')}>#</th>
                                                                                    <th onClick={() => sortTable('tbl-marcacoes', 1, 'data')}>Data</th>
                                                                                    <th onClick={() => sortTable('tbl-marcacoes', 2)}>Período</th>
                                                                                    <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Data registro</th>
                                                                                    <th className="text-center">Ações</th>
                                                                                </thead>
                                                                                <tbody style={{ fontSize: 12 }}>
                                                                                    {carregandoTabelasResumoSocio ? <tr><td colspan={3}><LoadRelatorio refLoading={refAtualizacao} heigth={305}></LoadRelatorio></td></tr> :
                                                                                        dadosResumo.marcacoes && dadosResumo.marcacoes.length > 0 ?
                                                                                            dadosResumo.marcacoes.map((x, i) =>
                                                                                                <tr >
                                                                                                    <td><span class="font-weight-medium">{i + 1}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.data}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.descricao}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.data_registro}</span></td>
                                                                                                    <td className="text-center">
                                                                                                        <LinkAlteracaoTabela action={() => { setAlterarEmTela(true); setIdAlteracaoEmTela(x.id); }}></LinkAlteracaoTabela>
                                                                                                        <LinkExclusaoTabela idItem={x.id} descricao={x.descricao_auditoria} url='/administracao/ferias/marcacao' acaoAposExclusao={() => { refreshTabelas(); mostrarToast('sucesso', 'Operação realizada com sucesso') }} acaoAposErro={() => refreshTabelas()}></LinkExclusaoTabela>
                                                                                                    </td>                                                                        </tr>
                                                                                            )
                                                                                            :
                                                                                            <tr>
                                                                                                <td colspan="4"><span className="font-weight-medium">Nenhum registro encontrado.</span></td>
                                                                                            </tr>}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel>
                                                                        <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '308px' }}>
                                                                            <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel" id="tbl-mercado">
                                                                                <thead>
                                                                                    <th className="contador" onClick={() => sortTable('tbl-mercado', 0, 'numero')}>#</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 1, 'data')}>Data</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 2)}>Tipo</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 3, 'socio')}>Rementente</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 4, 'socio')}>Destinatário</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 5, 'numero')}>Dias</th>
                                                                                    <th onClick={() => sortTable('tbl-mercado', 6, 'data_hora')}>Data registro</th>
                                                                                    <th className="text-center">Ações</th>
                                                                                </thead>
                                                                                <tbody style={{ fontSize: 12 }}>
                                                                                    {carregandoTabelasResumoSocio ? <LoadRelatorio refLoading={refAtualizacao} heigth={305}></LoadRelatorio> :
                                                                                        dadosResumo.lancamentos_mercado && dadosResumo.lancamentos_mercado.length > 0 ?
                                                                                            dadosResumo.lancamentos_mercado.map((x, i) =>
                                                                                                <tr rowspan={dadosResumo.lancamentos_mercado.length == 1 ? 5 : 0}>
                                                                                                    <td><span class="font-weight-medium">{i + 1}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.data}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.tipo_transferencia}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.remetente.sigla == null ? 'CONSELHO' : x.remetente.sigla + ' - ' + abreviarNomeSocio(x.remetente.nome, 3)}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.destinatario.sigla == null ? 'CONSELHO' : x.destinatario.sigla + ' - ' + abreviarNomeSocio(x.destinatario.nome, 3)}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.dias_utilizar}</span></td>
                                                                                                    <td><span class="font-weight-medium">{x.data_registro}</span></td>
                                                                                                    <td className="text-center">
                                                                                                        <LinkAlteracaoTabela action={() => { setIdAlteracaoMercado(x.id); setShowModalMercado(true); }}></LinkAlteracaoTabela>
                                                                                                        <LinkExclusaoTabela idItem={x.id} url='/administracao/ferias/mercado' acaoAposExclusao={() => { refreshTabelas(); mostrarToast('sucesso', 'Operação realizada com sucesso') }} acaoAposErro={() => refreshTabelas()}></LinkExclusaoTabela>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                            :
                                                                                            <tr>
                                                                                                <td colspan="4"><span className="font-weight-medium">Nenhum registro encontrado.</span></td>
                                                                                            </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel>
                                                                        <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '308px' }}>
                                                                            <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel" id="tbl-ocorrencias">
                                                                                <thead>
                                                                                    <th className="contador" onClick={() => sortTable('tbl-ocorrencias', 0, 'numero')}>#</th>
                                                                                    <th onClick={() => sortTable('tbl-ocorrencias', 1, 'data')}>Início</th>
                                                                                    <th onClick={() => sortTable('tbl-ocorrencias', 2, 'data')}>Fim</th>
                                                                                    <th onClick={() => sortTable('tbl-ocorrencias', 3)}>Tipo</th>
                                                                                    <th onClick={() => sortTable('tbl-ocorrencias', 4, 'data_hora')}>Data registro</th>
                                                                                </thead>
                                                                                <tbody style={{ fontSize: 12 }}>
                                                                                    {
                                                                                        carregandoTabelasResumoSocio ? <LoadRelatorio refLoading={refAtualizacao} heigth={305}></LoadRelatorio> :
                                                                                            dadosResumo.ocorrencias && dadosResumo.ocorrencias.length > 0 ? dadosResumo.ocorrencias.map((x, i) =>
                                                                                                <tr>
                                                                                                    <td><span className="font-weight-medium">{i + 1}</span></td>
                                                                                                    <td><span className="font-weight-medium">{x.inicio}</span></td>
                                                                                                    <td><span className="font-weight-medium">{x.fim}</span></td>
                                                                                                    <td><span className="font-weight-medium">{x.descricao}</span></td>
                                                                                                    <td><span className="font-weight-medium">{x.data_registro}</span></td>
                                                                                                </tr>
                                                                                            )
                                                                                                :
                                                                                                <tr>
                                                                                                    <td colspan="4"><span className="font-weight-medium">Nenhum registro encontrado.</span></td>
                                                                                                </tr>}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </TabPanel>
                                                                </Tabs>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12">
                                            <div className='card b-radius-10' style={{ zIndex: 1, minHeight: '760px' }}>
                                                <div className='card-body'>
                                                    <div class="mb-2 row-space-between" style={{ height: '40px' }}><div class="card-title" style={{ width: '100%', height: '40px', paddingBottom: '0px', marginBottom: '0px' }}>{navigationData()} </div></div>
                                                    <div className='dashboard-auditoria'>
                                                        <div className='resumo' style={{ padding: 5 }}>
                                                            {dadosAusenciasDia.resumo == undefined ? <></> :
                                                                (


                                                                    <>
                                                                        <div className='row mb-2'>
                                                                            {
                                                                                dadosAusenciasDia.resumoSaldo.map(x =>
                                                                                    <div className='col-4 bold'>{x.periodo.toString().split(':')[0]}: {parseFloat(x.horas || 0).toFixed(2) / 6}</div>
                                                                                )}
                                                                            { }
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                        <Tabs style={{ backgroundColor: '#F8F8F8' }}>
                                                            <TabList>
                                                                <Tab><span className={"icone-tab icon-Yacht "}></span>Férias</Tab>
                                                                <Tab><span className={"icone-tab icon-Barricade-2 "}></span> Ocorrências</Tab>
                                                            </TabList>
                                                            <TabPanel>
                                                                <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '580px' }}>
                                                                    <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel" id="tbl-ausencias">
                                                                        <thead>
                                                                            <th className="contador" onClick={() => sortTable('tbl-ausencias', 0, 'numero')}>#</th>
                                                                            <th onClick={() => sortTable('tbl-ausencias', 1, 'socio')}>Sócio</th>
                                                                            <th onClick={() => sortTable('tbl-ausencias', 2)}>Período</th>
                                                                            <th onClick={() => sortTable('tbl-ausencias', 3, 'data_hora')}>Data registro</th>
                                                                            <th className="text-center">Ações</th>
                                                                        </thead>
                                                                        <tbody style={{ fontSize: 12 }}>
                                                                            {
                                                                                carregandoTabelaAusencia ? <LoadRelatorio refLoading={refAtualizacao} heigth={580}></LoadRelatorio> :
                                                                                    dadosAusenciasDia.ausencias && dadosAusenciasDia.ausencias.length > 0 && dadosAusenciasDia.ausencias.map((x, i) =>
                                                                                        <tr>
                                                                                            <td><span className="font-weight-medium">{i + 1}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.sigla} - {x.nome}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.descricao}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.data_registro}</span></td>
                                                                                            <td className="text-center">
                                                                                                <LinkAlteracaoTabela action={() => { setAlterarEmTela(true); setIdAlteracaoEmTela(x.id); }}></LinkAlteracaoTabela>
                                                                                                <LinkExclusaoTabela idItem={x.id} descricao={x.descricao_auditoria} url='/administracao/ferias/marcacao' acaoAposExclusao={() => { refreshTabelas(); mostrarToast('sucesso', 'Operação realizada com sucesso') }} acaoAposErro={() => refreshTabelas()}></LinkExclusaoTabela>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                            {dadosAusenciasDia.ausencias && dadosAusenciasDia.ausencias.length == 0 ? <tr><td colspan='4'><span className="font-weight-medium">Nenhum registro encontrado.</span></td></tr> : <></>}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel>
                                                                <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '580px' }}>
                                                                    <table className="table table-sm table-borderless tabela-linha-destaque ordenavel" id="tbl-ocorrencias-dia">
                                                                        <thead>
                                                                            <th className="contador" onClick={() => sortTable('tbl-ocorrencias-dia', 0, 'numero')}>#</th>
                                                                            <th onClick={() => sortTable('tbl-ocorrencias-dia', 1, 'socio')}>Sócio</th>
                                                                            <th onClick={() => sortTable('tbl-ocorrencias-dia', 2)}>Data</th>
                                                                            <th onClick={() => sortTable('tbl-ocorrencias-dia', 3)}>Tipo</th>
                                                                            <th onClick={() => sortTable('tbl-ocorrencias-dia', 4, 'data_hora')}>Data Registro</th>
                                                                        </thead>
                                                                        <tbody style={{ fontSize: 12 }}>
                                                                            {
                                                                                carregandoTabelaAusencia ? <LoadRelatorio refLoading={refAtualizacao} heigth={580}></LoadRelatorio> :
                                                                                    dadosAusenciasDia.ocorrencias && dadosAusenciasDia.ocorrencias.length > 0 && dadosAusenciasDia.ocorrencias.map((x, i) =>
                                                                                        <tr>
                                                                                            <td><span className="font-weight-medium">{i + 1}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.sigla} - {x.nome}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.data}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.descricao}</span></td>
                                                                                            <td><span className="font-weight-medium">{x.data_registro}</span></td>
                                                                                        </tr>
                                                                                    )}

                                                                            {dadosAusenciasDia.ocorrencias && dadosAusenciasDia.ocorrencias.length == 0 ? <tr><td colspan='4'><span className="font-weight-medium">Nenhum registro encontrado.</span></td></tr> : <></>}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </TabPanel>
                                                        </Tabs>
                                                        <div className='resumo' style={{ padding: 5 }}>
                                                            {dadosAusenciasDia.resumoSaldo == undefined ? <></> :
                                                                (


                                                                    <>
                                                                        <div className='row mb-2'>
                                                                            <div className='col-2 italic' style={{ fontWeight: 'italic' }}>
                                                                                Saldo....:
                                                                            </div>
                                                                            {
                                                                                dadosAusenciasDia.resumoSaldo.map(x =>
                                                                                    <div className='col-3 bold'>{x.periodo.toString()}: {parseInt(x.qtd_maxima_periodo || 0) - parseFloat(x.horas ? x.horas : 0).toFixed(2) / 6}</div>
                                                                                )}
                                                                            { }
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Form>
                    )}

                </Formik>

            </>)
}
