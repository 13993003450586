import matchSorter from 'match-sorter'
import React from 'react'
import { InputSimples, InputPadrao, InputDatePicker, InputTimePicker, InputMonthPicker } from './Inputs'
import {
    InputPorArray, InputOrdenamento, InputFiltroEstabelecimentoSaude, InputMunicipio,
    InputCodigoProcedimento, InputPorteAnestesico, InputUsuarioApp, InputUsuarioAuditoria, InputTipoEstabelecimento,
    InputTabelaProcedimento, InputCro, InputAcoes, InputUsuario, InputAnoFiltro, InputPeriodoFerias, InputTipoOcorrencia, InputSimNao, InputSocioAtivos,
    InputTipoDataFiltro, InputMesFeriado, InputAnoFeriado, InputModulo, InputTipoDireitoFolga, InputStatusMarcacao, InputAnoGozo, InputTipoTabelaProcedimento, InputOperadoraSaude, InputMesDireitoFolga, InputAnoDireitoFolga, InputContratoStatus, InputAcomodacao, InputAnoAnestesiaOdonto,
    InputTipoExecutante, InputGuiaStatus, InputEmpresa, InputLoteGuiaFiltro, InputLoteStatus, InputStatusContratoParticular, InputContratoFiltro, InputContratoFiltroParticular, InputStatusEnvio, InputGuiaStatusParticular, InputSocio, InputSocioStatus, InputUsuarioSocio,
    InputTipoEscalador, InputSocioAtivosComConselho, InputMercadoTipo, InputClienteFinanceiro, InputMovimentoFinanceiro, InputEntidade, InputStatusMovimentoFinanceiro, InputStatusCorLancamento,
    InputStatusLancamento, InputTipoConta, InputBanco, InputTributoEsfera, InputModoLiquidacao, InputTipoCliente, InputMunicipioGOPrimeiro, InputTributoTipo, InputTipoLancamentoFinanceiro, InputTodasCategorias, InputCategoriaAlternativo, InputContaBancaria, InputCirurgiaoCombo, InputGrupo,
    InputTipoUsuario, InputBeneficiario, InputTipoGuiaPagamento,
    InputContasBancariaEmpresa
} from './InputsAutoComplete'
import { removeAcentos } from '../util/FuncoesComuns'
import { InputConselhoProfissional, InputStatusEnvioNota, InputStatusNota, InputTipoArquivo, InputReferenciaProducao, InputTipoMovimento } from './InputsAutoCompleteV2'

const FiltroObjeto = (props) => {
    const tipo = props.tipo
    switch (tipo) {
        case 'combo':
            return <InputPorArray {...props} />
        case 'combo-simples':
            return <InputPorArray {...props} multiplo={false} />
        case 'texto-global':
            return <InputPadrao {...props} />
        default:
            return <></>
    }
}

const FiltroDataGrid = (props) => {
    const tipo = props.tipo;

    switch (tipo) {
        case 'cro':
            return <InputCro {...props} />
        case 'socio':
        case 'socio_cooperativa':
        case 'socio_equipe':
            return <InputSocioAtivos {...props} />
        case 'periodo_marcacao':
            return <InputPeriodoFerias {...props} />
        case 'ano':
            return <InputAnoFiltro {...props} />
        case 'estabelecimento_saude':
            return <InputFiltroEstabelecimentoSaude {...props} />
        case 'data':
        case 'data_inicial':
        case 'data_final':
        case 'data_inicial_procedimento':
        case 'data_final_procedimento':
        case 'data_envio_inicial':
        case 'data_envio_final':
        case 'data_prevista_inicial':
        case 'data_prevista_final':
        case 'data_liquidacao_inicial':
        case 'data_liquidacao_final':
        case 'data_movimento_inicial':
        case 'data_movimento_final':
        case 'data_status_inicial':
        case 'data_status_final':
        case 'data_emissao_inicial':
        case 'data_emissao_final':
        case 'data_compensacao_inicial':
        case 'data_compensacao_final':
        case 'data_emissao':
            return <InputDatePicker {...props} />
        case 'hora_inicial_procedimento':
        case 'hora_final_procedimento':
        case 'hora_inicial_registro':
        case 'hora_final_registro':
            return <InputTimePicker {...props} />
        case 'tabela':
            return <InputTabelaProcedimento {...props} />
        case 'porte_anestesico':
            return <InputPorteAnestesico {...props} />
        case 'codigo_procedimento':
            return <InputCodigoProcedimento {...props} />
        case 'codigo_procedimento':
            return <InputCodigoProcedimento {...props} />
        case 'usuario':
            return <InputUsuario {...props} />
        case 'usuario_top':
        case 'usuario_alteracao_top':
            return <InputUsuario menuPlacement={'top'}  {...props} />
        case 'usuario_filtro_lote_top':
            return <InputUsuario menuPlacement={'top'} {...props} />
        case 'usuario_app':
            return <InputUsuarioApp {...props} />
        case 'usuario_auditoria':
            return <InputUsuarioAuditoria {...props} />
        case 'acoes':
            return <InputAcoes {...props} />
        case 'modulo':
            return <InputModulo {...props} />
        case 'combo-simples':
            return <InputPorArray {...props} multiplo={false} />
        case 'pesquisa-geral':
            return <InputPadrao {...props} />
        case 'tipo_ocorrencia':
            return <InputTipoOcorrencia {...props} />
        case 'input_sim_nao':
        case 'conta_ativa':
        case 'conta_principal':
        case 'conta_empresa':
        case 'usuario_ativo':
        case 'possui_movimento':
        case 'lancamento_com_guia':
        case 'liquidado':
        case 'possui_nfse':
            return <InputSimNao {...props} multiplo={false} />
        case 'tipo_data_filtro':
            return <InputTipoDataFiltro {...props} isClearable={false} />
        case 'mes_feriado':
            return <InputMesFeriado {...props} isClearable={false} />
        case 'mes_direito_folga':
            return <InputMesDireitoFolga {...props} isClearable={false} />
        case 'ano_feriado':
            return <InputAnoFeriado {...props} multiplo={false} />
        case 'ano_direito_folga':
            return <InputAnoDireitoFolga {...props} multiplo={false} />
        case 'tipo_direito_folga':
            return <InputTipoDireitoFolga {...props} />
        case 'status_marcacao':
            return <InputStatusMarcacao {...props} />
        case 'ano_gozo':
            return <InputAnoGozo {...props} />
        case 'municipio':
            return <InputMunicipio {...props} />
        case 'tipo_estabelecimento':
            return <InputTipoEstabelecimento {...props} />
        case 'tipo_tabela_procedimento':
            return <InputTipoTabelaProcedimento {...props} />
        case 'operadora_saude':
            return <InputOperadoraSaude {...props} />
        case 'contrato_status':
            return <InputContratoStatus {...props} />
        case 'acomodacao':
            return <InputAcomodacao {...props} />
        case 'tipo_executante':
            return <InputTipoExecutante {...props} />
        case 'guia_status_convenio':
            return <InputGuiaStatus status_guia_convenio {...props} />
        case 'guia_status_particular':
            return <InputGuiaStatusParticular  {...props} />
        case 'guia_status':
            return <InputGuiaStatus {...props} />
        case 'status_envio':
            return <InputStatusEnvio {...props} />
        case 'empresa':
            return <InputEmpresa {...props} />
        case 'ano_anestesia':
            return <InputAnoAnestesiaOdonto {...props} multiplo={false} />
        case 'referencia_lote':
            return <InputMonthPicker {...props} />
        case 'referencia_producao':
            return <InputReferenciaProducao {...props} />
        case 'lote':
            return <InputLoteGuiaFiltro {...props} />
        case 'status_lote':
            return <InputLoteStatus {...props} />
        case 'status_contrato_particular':
            return <InputStatusContratoParticular {...props} />
        case 'contrato_filtro':
            return <InputContratoFiltro {...props} />
        case 'contrato_particular_filtro':
            return < InputContratoFiltroParticular {...props} />
        case 'consulta_socio':
            return < InputSocio {...props} />
        case 'status_socio':
            return < InputSocioStatus {...props} />
        case 'usuario_socio':
            return < InputUsuarioSocio {...props} />
        case 'tipo_escalador':
            return < InputTipoEscalador {...props} />
        case 'destinatario':
        case 'remetente':
            return < InputSocioAtivosComConselho {...props} />
        case 'mercado_tipo':
            return < InputMercadoTipo {...props} />
        case 'cliente':
            return < InputClienteFinanceiro {...props} />
        case 'movimento_financeiro':
            return <InputMovimentoFinanceiro {...props} />
        case 'estado_lancamento':
            return <InputStatusCorLancamento {...props} />
        case 'entidade_financeiro':
            return <InputEntidade {...props} />
        case 'categoria_financeiro':
            return <InputCategoriaAlternativo {...props} />
        case 'status_movimento':
            return <InputStatusMovimentoFinanceiro {...props} />
        case 'status_lancamento':
            return <InputStatusLancamento {...props} />
        case 'tipo_conta':
            return <InputTipoConta {...props} />
        case 'banco':
            return <InputBanco {...props} />
        case 'tributo_esfera':
            return <InputTributoEsfera {...props} />
        case 'tributo_tipo':
            return <InputTributoTipo {...props} />
        case 'tipo_pagamento':
            return <InputModoLiquidacao {...props} />
        case 'tipo_cliente':
            return <InputTipoCliente {...props} />
        case 'municipio_go_primeiro':
            return <InputMunicipioGOPrimeiro {...props} />
        case 'tipo_lancamento':
            return <InputTipoLancamentoFinanceiro {...props} />
        case 'todas_categorias':
            return <InputTodasCategorias {...props} />
        case 'conta_bancaria':
            return <InputContaBancaria {...props} />
        case 'cirurgiao':
            return <InputCirurgiaoCombo {...props} />
        case 'grupo':
            return <InputGrupo {...props} />
        case 'tipo_usuario':
            return <InputTipoUsuario {...props} />
        case 'beneficiario':
            return <InputBeneficiario {...props} />
        case 'conselho_profissional':
            return <InputConselhoProfissional {...props} />
        case 'tipo_arquivo':
            return <InputTipoArquivo {...props} />
        case 'status_envio_nota':
            return <InputStatusEnvioNota {...props} />
        case 'status_nota':
            return <InputStatusNota {...props} />
        case 'tipo_guia_pagamento':
            return <InputTipoGuiaPagamento {...props} multiplo={false} />
        case 'conta_bancaria_emrepsa':
            return <InputContasBancariaEmpresa {...props} multiplo={false} />
        case 'tipo_movimento':
            return <InputTipoMovimento {...props} />
        default:
            return <></>
    }
}

const Ordenaveis = (props) => {
    return (
        <>
            <InputOrdenamento label="" name="ordenamento" placeholder="Selecione" icone="icon-A-Z" {...props} />
        </>
    )
}

const filtragemPorString = (data, coluna, filtro) => {
    /*filtro pode ser string ou array de strings*/
    let cloneData = [...data]
    let arrayPesquisado = []
    if (Array.isArray(filtro)) {
        filtro.map(item => {
            arrayPesquisado = arrayPesquisado.concat(cloneData.filter(row => row[coluna] === item))
            return item
        })
        cloneData = arrayPesquisado
    } else {
        cloneData = cloneData.filter(row => row[coluna] === filtro)
        // matchSorter(cloneData, filtro, { keys: [row => row[coluna]] })
    }
    return cloneData
}

const filtragemPorStringAlternativa = (data, coluna, filtro) => {

    /*filtro pode ser string ou array de strings*/
    let cloneData = [...data]
    let arrayPesquisado = []
    if (Array.isArray(filtro)) {
        filtro.map(item => {
            arrayPesquisado = arrayPesquisado.concat(cloneData.filter(row => row[coluna] && row[coluna].includes(item)))
            return item
        })
        cloneData = arrayPesquisado
    } else {
        cloneData = cloneData.filter(row => row[coluna].includes(filtro))
        // matchSorter(cloneData, filtro, { keys: [row => row[coluna]] })
    }
    return cloneData
}

const pesquisaGlobal = (dados, colunas, texto) => {
    const retorno = dados.filter(item => {
        const row = colunas.map(coluna => item[coluna])
        return removeAcentos(row.join(' ').toLocaleLowerCase()).includes(removeAcentos(String(texto || "").toLowerCase()))
    })
    return retorno;
}

const combo = (data, coluna, filtro) => {

    if (filtro && filtro.length > 0) {
        const novoFiltro = filtro.map(item => item.label)
        return filtragemPorString(data, coluna, novoFiltro)
    }

    return data
}

const comboAlternativa = (data, coluna, filtro) => {

    if (filtro && filtro.length > 0) {

        const novoFiltro = filtro.map(item => item.label)

        return filtragemPorString(data, coluna, novoFiltro)
    }

    return data
}

const comboAlternativaPesquisa = (data, coluna, filtro) => {

    if (filtro && filtro.length > 0) {

        const novoFiltro = filtro.map(item => item.label)
        return filtragemPorStringAlternativa(data, coluna, novoFiltro)
    }

    return data
}


const comboSimples = (data, coluna, filtro) => {
    if (filtro && filtro.label)
        return filtragemPorString(data, coluna, filtro.label)

    return data
}

const textoGlobal = (data, colunas, filtro) => {
    return pesquisaGlobal(data, colunas, filtro)
}


const metodoFiltragem = {
    'combo': combo,
    'combo-simples': comboSimples,
    'texto-global': textoGlobal
}

const metodoFiltragemAlternativa = {
    'combo': comboAlternativa,
    'combo-simples': comboSimples,
    'texto-global': textoGlobal,
    'combo-alternativa': comboAlternativaPesquisa
}

const toStringFiltro = (tipo, value) => {
    switch (tipo) {
        case 'combo':
            return value.map(item => item.label).join(', ')
        case 'combo-simples':
            return value.label
        case 'texto-global':
            return value
        default:
            return value || ''
    }
}


// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <InputSimples
            label=""
            name="search2"
            type="text"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Busca em ${count} registros...`}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Todos</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Remover Filtro</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

export {
    SliderColumnFilter,
    NumberRangeColumnFilter,
    SelectColumnFilter,
    filterGreaterThan,
    fuzzyTextFilterFn,
    DefaultColumnFilter,
    FiltroObjeto,
    metodoFiltragem,
    toStringFiltro, pesquisaGlobal, Ordenaveis, FiltroDataGrid, metodoFiltragemAlternativa
}