
import React, { useEffect, useState } from 'react';
import '../assets/css/theme.css';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { FaKeyboard, FaExclamation } from 'react-icons/fa'
import {
    FloatingMenu,
    MainButton,
    ChildButton,
} from 'react-floating-button-menu';


const BotaoPadrao = (props) => {
    const [id, setId] = useState(Math.random().toString().replace(".", ""))
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""

    return (<>
        <ReactTooltip className={"atalhoTooltip " + classeTooltip} id={"salvar" + id} place={props.place ? props.place : 'top'} type={props.type ? props.type : 'dark'} effect='solid'>
            <span className={"atalhoTooltip "}><FaKeyboard className={'iconeAtalhoTooltip'} />CTRL + ALT + S</span>
        </ReactTooltip>
        <ReactTooltip className={"atalhoTooltip"} id={"salvarENovo" + id} place={props.place ? props.place : 'top'} type={props.type ? props.type : 'dark'} effect='solid'>
            <span className={"atalhoTooltip "}><FaKeyboard className={'iconeAtalhoTooltip'} />{props.idTooltip === "salvarENovo" ? 'CTRL + ALT + N' : 'CTRL + ALT + S'}</span>
        </ReactTooltip>
        {props.textoTooltip && <ReactTooltip className={"atalhoTooltip"} id={"padrao" + id} place={props.place ? props.place : 'bottom'} type={props.type ? props.type : 'dark'} effect='solid'>
            <span className={"atalhoTooltip "}></span><span className={'texto-tooltip'}>{props.textoTooltip}</span>
        </ReactTooltip>}
        <button id={id} data-tip data-for={props.idTooltip + id} type={props.type || "submit"} className={`btn ${props.classe || "btn btn-primary primary"}`} {...props} onClick={props.click}>{props.disabled ? (<>
            <span className="spinner-grow spinner-grow-sm text-light" role="status" aria-hidden="true"></span>
            <span className="sr-only">Aguarde...</span> Aguarde...</>
        ) : (<><span className={props.icone}></span>{props.texto}</>)} </button>
    </>)
}

const BotaoAlternativo = (props) => {

    const [isOpen, setIsOpen] = useState(false)
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    var keysButton = Object.keys(props.botoes)
    var childButtonArray = []
    var reactTooltip = []
    const idPrincipal = Math.random().toString().replace('.', '')
    var maisAcoes = document.getElementById('more')
    var menosAcoes = document.getElementById('less')
    var documento = document.getElementsByClassName('sc-bdVaJa frcQzm')
    useEffect(() => {
        documento[0].setAttribute('class', 'c-bdVaJa frcQzm col-12 ajuste')
    }, [])

    for (let i in keysButton) {

        var id = Math.random().toString().replace(".", "")

        childButtonArray.push(
            < ChildButton
                icon={<i className={props.botoes[keysButton[i]].icone} style={{ fontSize: 15 }
                }></i >}
                className='color-button'
                data-tip data-for={'padrao' + id}
                size={40}
                onClick={props.botoes[keysButton[i]].click}
            />
        )

        reactTooltip.push(
            <ReactTooltip className={"atalhoTooltip"} id={"padrao" + id} place={props.place ? props.place : 'bottom'} type={props.type ? props.type : 'dark'} effect='solid'>
                <span className={"atalhoTooltip "}></span><span className={'texto-tooltip'}>{props.botoes[keysButton[i]].textoTooltip}</span>
            </ReactTooltip>

        )
    }

    return (<>

        {reactTooltip}
        <ReactTooltip className={"atalhoTooltip"} id={"master" + idPrincipal} place={props.place ? props.place : 'bottom'} type={props.type ? props.type : 'dark'} effect='solid'>
            <span className={"atalhoTooltip "}></span><span className={'texto-tooltip'}>{maisAcoes !== null ? 'Mais ações' : 'Menos Ações'}</span>
        </ReactTooltip>
        <FloatingMenu
            slideSpeed={800}
            direction="left"
            spacing={8}
            isOpen={isOpen}
        >
            <MainButton
                id='more'
                iconResting={<i className={'icon-ellipsi'} style={{ fontSize: 20 }
                }></i >}
                iconActive={<i className={'icon-Arrow-Left'} style={{ fontSize: 20, lineHeight: 20 }
                }></i >}
                backgroundColor=""
                className=''
                data-tip data-for={'master' + idPrincipal}
                onClick={() => { setIsOpen(!isOpen); maisAcoes !== null ? maisAcoes.setAttribute("id", "less") : menosAcoes !== null && menosAcoes.setAttribute('id', 'more'); }}
                size={40}
            />
            {childButtonArray}
        </FloatingMenu>

    </>)
}


const BotaoMenu = props => {
    const [toggled, setToggled] = useState(false)
    const [buttons, setButtons] = useState([])
    let className = "floating-menu"


    useEffect(() => {
        const newButton = []
        newButton.push(<BotaoPadrao click={_ => setToggled(!toggled)} icone={props.icone} classe=' btn btn-primary primary btn-filter ' textoTooltip={'Abrir'} idTooltip={"padrao"} />)

        if (toggled) {
            className += " open";
            newButton.push(props.children)
        }

        setButtons(newButton)
    }, [toggled])

    useEffect(() => {
        if (props.iniciarAberto)
            setToggled(true)
    }, [props.iniciarAberto])

    return (
        <div className={className} {...props}>
            {buttons}
        </div>
    )
}

export { BotaoPadrao, BotaoAlternativo, BotaoMenu }