
import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../../components/Headers'
// import { Alterar, Excluir, Visualizar} from './Acoes'
import DataGrid from '../../../../../components/DataGrid'
import DataGridAlternativa from '../../../../../components/DataGridAlternativa'
import { consultar } from '../../../../../util/AcessoApi'


const ConsultaEscalador = (props) => {
    const [data, setData] = useState([])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-lancamento-saldo',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'socio',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'socio.id'
                        }
                    },
                    {
                        Header: 'Referência',
                        accessor: 'ano',
                        ordenamento: {
                            tipo: 'numero',
                        },
                        filtro: {
                            tipo: 'ano_gozo',
                            idColuna: 'ano'
                        }
                    },
                    {
                        Header: 'Período Inicial',
                        accessor: 'periodo_inicial',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Período Final',
                        accessor: 'periodo_final',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Dias para Gozo',
                        accessor: 'dias_direito',
                        ordenamento: {
                            tipo: 'numero',
                        }
                    },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        }
                    }
                ],
            },
        ],
        []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} 
                caminhoBase='/administracao/ferias/lancamento-saldo' modulo={props.modulo} titulo={'Consulta de Lançamentos (Dias para Gozo)'}
                tituloLista={'Lista Lançamentos (Dias para Gozo)'} 
                urlCadastro='/administracao/ferias/lancamento-saldo/cadastro' icone={'icon-Address-Book2'} caminhoConsultaFiltros='/administracao/ferias/lancamentoPaginacao' />
                    
            </div>
        </>
    )
}

export default ConsultaEscalador