const ACOES = {
    INSERIR: 2,
    ALTERAR: 3,
    EXCLUIR: 4,
    CONSULTAR: 5,
    VISUALIZAR: 6,
    VALIDAR: 7,
    REENVIAR: 8,
    IMPORTAR: 9,
    ENVIAR: 10,
    PREFERENCIAL: 11,
    OBSERVACAO_AGENDA: 12,
    CANCELAR_AGENDA: 17,
    FINALIZAR_AGENDA: 18,
    CONFIRMAR_AGENDA: 19,
    ALTERAR_STATUS_AGENDA: 20,
    TROCAR_SOCIO: 22,
    EXCLUIR_SOCIOS: 23,
    TROCAR_STATUS: 23,
    REVERTER_EXCLUSAO: 27,
    EMITIR: 28,
    GUIA_COMPLEMENTAR: 31,
    LISTAR: 33,
    REMOVER_GUIA_COMPLEMENTAR: 34,
    FECHAR_MOVIMENTO_FINANCEIRO: 35,
    VISUALIZAR_COM_ASSINATURA: 36,
    LIQUIDAR: 37,
    REMOVER_LIQUIDACAO: 38,
    CANCELAR_LANCAMENTO: 39,
    ADICIONAR_MOVIMENTO: 40,
    ADICIONAR_CHEQUE: 42,
    ATUALIZAR_STATUS_COOPANEST: 43,
    CONFERENCIA_GUIA: 45,
    GERAR_LOTES_AUTOMATICOS: 46,
    ADICIONAR_GUIA: 49,
    DESFAZER_MAPA_HORAS: 48,
    INSERIR_ARQUIVOS_GUIA: 50,
    INSERIR_ARQUIVOS_GUIA_PARTICULAR: 51,
    RECUPERAR_RASCUNHO: 52,
    REMOVER_GUIAS: 53,
    REMOVER_LOTE: 54,
    ALTERAR_GUIA_MAPA_HORAS: 57,
    ALTERAR_SOCIO_GUIA: 84,
    ADICIONAR_GUIAS_EXTRA: 85,
    ALTERAR_SOCIO_GUIA: 84,
    ALTERAR_SOCIO_GUIA_MAPA_HORA: 86,
    TROCAR_STATUS_NOTA: 89,
    TROCAR_STATUS_ENVIO: 90,
    SUBSTITUIR_NOTA: 91,
    CANCELAR_NOTA: 92,
    TROCAR_SOCIO_SEM_RESTRICAO: 93,
    RECIBO: 94,
    VISUALIZAR_PAGAMENTO: 95,
    EMITIR_NOTA_FISCAL: 96,
    ENVIAR_EMAIL_NOTA_FISCAL: 97,
    EXPORTAR_LANCAMENTO: 98
}

const SERVICE = {
    DOCWAGE: process.env.REACT_APP_BATCH_OF_GUIDES_API + '/Processor/add',
    ENGINE_INTEGRATION: process.env.REACT_APP_ENGINE_INTEGRATION_API + '/Processor/add',
    BATCH_OF_GUIDES: process.env.REACT_APP_BATCH_OF_GUIDES_API + '/Processor/add-batch-of-guides',
    BATCH_UPDATE_STATUS: process.env.REACT_APP_BATCH_OF_GUIDES_API + '/Processor/batch-update-status'
}

const RETENCOES = {
    acrescimo: '(+) ACRÉSCIMO',
    desconto: '(-) DESCONTO',
    juros_multas: '(+) JUROS OU MULTAS',
    imposto_nfse: '(-) IMPOSTO NFSE',
    glosas: '(-) GLOSAS',
    outros_impostos: '(-) OUTROS IMPOSTOS'
}

const TIPO_ARQUIVO = {
    MAPA_DE_HORAS: `{"value": 1, "label": "Mapa de Horas"}`,
    EXTRA: `{"value": 2, "label": "Extra"}`,
    AJUDA: `{"value": 3, "label": "Ajuda"}`,
    TERCEIRO_HOSPITAL: `{"value": 4, "label": "Terceiro Hospital"}`,
}

const MODULO = {
    '/controleacesso/usuario': 1,
    '/controleacesso/grupo': 2,
    '/controleacesso/modulo': 3,
    '/controleacesso/auditoria': 42,
    '/controleacesso/auditoria/0': 42,
    '/controleacesso/usuarioapp': 44,
    '/controleacesso/auditoriaapp/0': 48,
    '': 4,
    '/administracao/empresa': 5,
    '/administracao/socio': 6,
    '/parametros/cbos': 7,
    '/parametros/analisedados': 146,
    '/parametros/analisedados/urlProducaoFaturamento': 146,
    '/parametros/cbospaginacao/0': 7,
    '/administracao/operadorasaude': 8,
    '/administracao/operadorasaudepaginacao/0': 8,
    '/administracao/estabelecimentosaude': 9,
    '/administracao/estabelecimentosaudepaginacao/0': 9,
    '/administracao/cirurgiao': 10,
    '/administracao/cirurgiaopaginacao/0': 10,
    '/administracao/escalador': 11,
    '/faturamento/tabelaprocedimento': 12,
    '/faturamento/tabelaprocedimentopaginacao/0': 12,
    '/parametros/tipoescalador': 13,
    '/faturamento/procedimento': 14,
    '/faturamento/procedimentopaginacao/0': 14,
    '/administracao/agendaescalador': 15,
    '/administracao/socio-ocorrencia': 17,
    '/administracao/socio-ocorrenciapaginacao/0': 17,
    '/relatorio/socio': 27,
    '/administracao/departamento': 29,
    '/faturamento/acomodacao': 30,
    '/faturamento/cid10': 31,
    '/faturamento/beneficiario': 32,
    '/faturamento/beneficiariopaginacao/0': 32,
    '/faturamento/contrato': 34,
    '/faturamento/contratopaginacao/0': 34,
    '/faturamento/contratoparticular': 111,
    '/faturamento/contratoparticularpaginacao/0': 111,
    '/faturamento/lote': 35,
    '/faturamento/obterGuias': 46,
    '/faturamento/lotepaginacao/0': 35,
    '/administracao/registroatividades': 39,
    '/administracao/agendareserva': 38,
    '/administracao/agendareservasociojustificativa': 38,
    '/administracao/agendareservasocio': 38,
    '/administracao/cancelarreservasociojustificativa': 38,
    '/administracao/agendapreferencia': 38,
    '/administracao/agendareservafinalizar': 38,
    '/administracao/agendareservastatushistorico': 38,
    '/administracao/plantao': 49,
    '/administracao/plantaoalcancavelexcluirrecorrencia': 49,
    '/administracao/plantaoalcancavelexcluirproximarecorrencia': 49,
    '/administracao/plantaoalcancavel': 49,
    '/administracao/plantaoestabelecimentoexcluirrecorrencia': 53,
    '/administracao/plantaoestabelecimentoexcluirproximarecorrencia': 53,
    '/administracao/plantaoestabelecimento': 53,
    '/administracao/plantao-estabelecimento': 53,
    '/administracao/plantao-alcancaveis': 49,
    '/administracao/trocasociohistorico': 38,
    '/administracao/plantaoalcancavelvisualizacao': 49,
    '/administracao/lembrete': 50,
    '/administracao/agendareservaenvio': 38,
    '/administracao/rotinas': 54,
    '/administracao/auditoriarotina': 54,
    '/administracao/plantaoestabelecimento': 53,
    '/administracao/plantaoestabelecimento': 53,
    '/administracao/ferias/periodo-gozo': 56,
    '/administracao/ferias/lancamentosaldo': 58,
    '/administracao/ferias/marcacao': 59,
    '/administracao/ferias/marcacaopaginacao/0': 59,
    '/administracao/ferias/mercado': 60,
    '/administracao/ferias/lancamentopaginacao/0': 58,
    '/administracao/ferias/periodogozo': 56,
    '/administracao/grupo-escala': 64,
    '/administracao/feriadospaginacao/0': 67,
    '/administracao/feriados': 67,
    '/administracao/notificacaopaginacao/0': 71,
    '/administracao/notificacao': 71,
    '/administracao/notificacaoenvio': 71,
    '/administracao/direitofolga': 72,
    '/administracao/direitofolgapaginacao/0': 72,
    '/controleacesso/lixeira': 73,
    '/controleacesso/lixeira/0': 73,
    '/controleacesso/rascunho': 144,
    '/controleacesso/rascunho/0': 144,
    '/controleproducao/referencia': 140,
    '/controleproducao/referenciapaginacao/0': 140,
    '/faturamento/parametrooperadorasaudepaginacao/0': 74,
    '/faturamento/parametrooperadorasaude': 74,
    '/faturamento/guiapaginacao/0': 76,
    '/faturamento/guia': 76,
    '/faturamento/guiaalteracao': 76,
    '/faturamento/guiaalterarlote': 108,
    '/faturamento/guiaalterarlotemultiplo': 108,
    '/faturamento/guiaAlterarConferencia': 76,
    '/faturamento/anestesiaodontologiapaginacao/0': 80,
    '/faturamento/anestesiaodontologia': 80,
    '/relatorio/guiaestabelecimentosaude': 81,
    '/relatorio/guiaoperadorasaude': 82,
    '/relatorio/guiasocioequipe': 83,
    '/relatorio/guiasociocooperativa': 84,
    '/faturamento/relatorioguiageral': 85,
    '/relatorio/guiacorrecao': 86,
    '/relatorio/estabelecimentosaudedescoberto': 87,
    '/relatorio/agendamentosocio': 88,
    '/relatorio/procedimentoscirurgiaosocio': 89,
    '/relatorio/guiasenviadas': 113,
    '/relatorio/resumooperadorasaude': 90,
    '/relatorio/resumoestabelecimentosaude': 91,
    '/administracao/ferias/relatorioprofissionalutilizadorecebido': 92,
    '/administracao/ferias/relatorioprofissionalutilizadorecebidoresumo': 93,
    '/administracao/ferias/relatoriomercado': 94,
    '/relatorio/relatorioocorrencia': 95,
    '/relatorio/plantao': 96,
    '/relatorio/duracaoprocedimento': 97,
    '/relatorio/duracaotodosprocedimentos': 98,
    '/relatorio/socio': 99,
    '/relatorio/socioconjuge': 100,
    '/relatorio/sociodependente': 101,
    '/relatorio/socioendereco': 102,
    '/relatorio/socioempresa': 103,
    '/relatorio/sociogrupo': 104,
    '/relatorio/sociohierarquizacao': 105,
    '/relatorio/resumoguiasintetico': 141,
    '/relatorio/resumoguiaanalitico': 142,
    '/relatorio/relatorioguiamapa': 143,
    '/faturamento/porteanestesicoparticular': 79,
    '/faturamento/porteanestesicoparticularpaginacao/0': 79,
    '/faturamento/guiaparticularpaginacao/0': 107,
    '/faturamento/guia-particular': 107,
    '/faturamento/guiaparticular': 107,
    '/faturamento/guiacomplementar': 107,
    '/faturamento/removerguiacomplementar': 107,
    '/faturamento/guiaalterarstatus': 109,
    '/faturamento/lotealterarstatus': 110,
    '/faturamento/guiaalterarstatusmultiplo': 109,
    '/faturamento/guiaparticularalterarstatusmultiplo': 112,
    '/faturamento/guiaparticularalterarstatus': 112,
    '/faturamento/guiaInserirArquivos': 137,
    '/administracao/plantaoestabelecimentovisualizacao': 53,
    '/administracao/departamentopaginacao/0': 29,
    '/administracao/empresapaginacao/0': 5,
    '/administracao/sociopaginacao/0': 6,
    '/administracao/escaladorpaginacao/0': 11,
    '/administracao/ferias/periodogozopaginacao/0': 56,
    '/administracao/ferias/mercadopaginacao/0': 60,
    '/administracao/grupoescalapaginacao/0': 64,
    '/controleacesso/grupopaginacao/0': 2,
    '/faturamento/acomodacaopaginacao/0': 30,
    '/faturamento/cid10paginacao/0': 31,
    '/controleacesso/usuariopaginacao/0': 1,
    '/controleacesso/usuarioapppaginacao/0': 44,
    '/administracao/registroatividadespaginacao/0': 39,
    '/financeiro/movimentofinanceiro': 114,
    '/financeiro/movimentofinanceirovisualizacao': 114,
    '/financeiro/movimentofinanceiropaginacao/0': 114,
    '/financeiro/lancamento': 116,
    '/financeiro/lancamentovisualizacao': 116,
    '/financeiro/lancamentopaginacao/0': 116,
    // '/financeiro/lancamento': 116,
    '/financeiro/fechamento': 114,
    '/financeiro/liquidacaolancamento': 116,
    '/financeiro/contabancariapaginacao/0': 119,
    '/financeiro/contabancaria': 119,
    '/administracao/tipoocorrenciapaginacao/0': 124,
    '/administracao/tipo-ocorrencia': 124,
    '/financeiro/bancopaginacao/0': 125,
    '/financeiro/banco': 125,
    '/financeiro/tributopaginacao/0': 126,
    '/financeiro/tributo': 126,
    '/financeiro/tributoaliquotapaginacao/0': 131,
    '/financeiro/tributoaliquota': 131,
    '/financeiro/relatoriolancamentomovimento': 127,
    '/financeiro/relatoriomovimentosintetico': 128,
    '/financeiro/relatoriomovimentoanalitico': 129,
    '/financeiro/relatoriomovimentoreceita': 130,
    '/financeiro/relatoriocliente': 137,
    '/relatorio/relatorioagrupadorporestabelecimentosaude': 147,
    '/financeiro/clientepaginacao/0': 115,
    '/financeiro/cliente': 115,
    '/financeiro/chequepaginacao/0': 133,
    '/financeiro/cheque': 133,
    '/financeiro/lancamentofinanceiro': 134,
    '/financeiro/notafiscalpaginacao/0': 136,
    '/financeiro/notafiscal': 136,
    '/financeiro/notafiscal/visualizacaoErroNota/0': 136,
    '/financeiro/notafiscal/visualizacaoErroNota': 136,
    '/financeiro/lancamentofinanceirovisualizacao': 134,
    '/financeiro/lancamentofinanceiropaginacao/0': 134,
    '/financeiro/categoriapaginacao/0': 135,
    '/financeiro/categoria': 135,
    '/finaceiro/adicionarChequeLancamento': 116,
    '/finaceiro/adicionarChequeLancamentoFinanceiro': 134,
    '/controleAcesso/desfazerMapaHoras': 140,
    '/controleproducao/guiamapahoras': 145,
    '/controleproducao/guiamapahorapaginacao/0': 145,
    '/controleproducao/extraguias': 145,
    '/faturamento/alterarsocioguiaparticular': 107,
    '/faturamento/alterarsocioguia': 76,
    '/relatorio/guiaestabelecimentosaudeanalitico': 162,
    '/controleproducao/guiamapahora': 145,
    '/auditoria/guiapagamento': 148,
    '/auditoria/guiapagamentopaginacao/0': 148,
    '/relatorio/apresentadorepassadooperadorasaude': 149,
    '/relatorio/apresentadorepassadoestabelecimentosaude': 150,
    '/relatorio/analiticorepassadoestabelecimentosaude': 151,
    '/relatorio/sinteticopacienterepassado': 152,
    '/relatorio/guiasnaobaixadas': 153,
    '/relatorio/resumoguiasconferidassintetico': 154,
    '/relatorio/resumoguiasconferidasanalitico': 155,
    '/relatorio/notafiscal': 157,
    '/faturamento/relatorioguianfse': 158,
    '/relatorio/dmsnotafiscal': 159,
    '/relatorio/fluxocaixa': 165,

}

const GUIA_STATUS = {
    EM_AUDITORIA_EQUIPE: 18,
    RECUSADO_PELA_AUDITORIA_COOPANESTGO: 19,
    REALIZADA: 1,
    RECUSADO_PELA_AUDITORIA_EQUIPE: 20,
    EM_PROCESSAMENTO_PARA_REPASSE_COOPANESTGO: 21,
    LIBERADO_PARA_DIGITACAO: 2,
    CONFERIDA: 4,
    FATURADA: 5,
    PARA_CONFERENCIA: 6,
    ENVIADA_COOPANESTGO: 7,
    RECUSADA_COOPANESTGO: 8,
    EM_PROCESSO_DE_FATURAMENTO_COOPANESTGO: 22,
    PENDENTE: 3,
    AGUARDANDO_MEIO_FISICO_COOPANESTGO: 9,
    RECEBIDA_COOPANESTGO: 10,
    PARA_AUDITORIA: 11,
    EM_AUDITORIA_COOPANESTGO: 12,
    BAIXADA: 13,
    DEVOLVIDA_PELA_COOPANESTGO: 14,
    CANCELADA: 23,
    NAO_AUTORIZADO_PELO_CONVENIO: 15,
    BAIXADA_PARCIALMENTE: 16,
    AGUARDANDO_RECEBIMENTO_PELA_EQUIPE: 17,
    NAO_SOLICITADO_AO_CONVENIO: 24,
};



const MODULOS_SISTEMA = {

    RELATORIO_AGENDAMENTO: 36,
    RELATORIO_AGRUPADO_ESTABELECIMENTO_SAUDE: 81,
    RELATORIO_AGRUPADO_OPERADORA_SAUDE: 82,
    RELATORIO_AGRUPADO_SOCIO_COOPANEST_GO: 84,
    RELATORIO_AGRUPADO_SOCIO_EQUIPE: 83,
    RELATORIO_ANALITICO_ESTABELECIMENTO_SAUDE: 147,
    RELATORIO_ANALITICO_PRODUCAO_DIGITACAO: 142,
    RELATORIO_ANUAL_GUIAS_NAO_BAIXADAS: 153,
    RELATORIO_APRESENTADO_RECEBIDO_OPERADORA_SAUDE: 149,
    RELATORIO_APRESENTADO_RECEBIDO_ESTABELECIMENTO_SAUDE: 150,
    RELATORIO_APRES_RECEB_ANALITICO_ESTABELECIMENTO_SAUDE: 151,
    RELATORIO_APRES_RECEB_PACIENTE_GUIA: 152,
    RELATORIO_CIRURGIAO_SOCIO: 41,
    RELATORIO_DE_AGENDAMENTO: 65,
    RELATORIO_DE_CLIENTE: 137,
    RELATORIO_DE_CONTAS_A_PAGAR: 138,
    RELATORIO_DE_ESCALA_PLANTAO_ALCANCAVEIS: 96,
    RELATORIO_DE_FERIAS: 61,
    RELATORIO_DE_GUIAS_CONFERIDA_ANALITICO: 155,
    RELATORIO_DE_GUIAS_CONFERIDA_SINTETICO: 154,
    RELATORIO_DE_GUIAS_ENVIADAS: 113,
    RELATORIO_DE_LOTE: 139,
    RELATORIO_DE_NOTAS_FISCAIS: 157,
    RELATORIO_DE_OCORRENCIAS: 63,
    RELATORIO_DE_TESTE: 120,
    RELATORIO_DURACAO_PROCEDIMENTO: 97,
    RELATORIO_DURACAO_PROCEDIMENTO_CIRURGIA: 98,
    RELATORIO_ESCALA_PLANTAO_ALCANCAVEIS: 51,
    RELATORIO_ESTABELECIMENTO_SAUDE_DESCOBERTO: 87,
    RELATORIO_FATURAMENTO: 78,
    RELATORIO_GUIA: 77,
    RELATORIO_GUIA_GERAL: 85,
    RELATORIO_GUIA_GERAL_CORRECAO: 86,
    RELATORIO_GUIA_NFSE: 158,
    RELATORIO_GUIAS_MAPA_HORAS: 143,
    RELATORIO_LANCAMENTO_FINANCEIRO: 118,
    RELATORIO_LANCAMENTOS: 127,
    RELATORIO_MERCADO: 94,
    RELATORIO_MOVIMENTO_ANALITICO: 129,
    RELATORIO_MOVIMENTO_FINANCEIRO: 117,
    RELATORIO_MOVIMENTO_RECEITA: 130,
    RELATORIO_MOVIMENTO_SINTETICO: 128,
    RELATORIO_OCORRENCIAS: 95,
    RELATORIO_PLANTAO: 52,
    RELATORIO_PROCEDIMENTO: 37,
    RELATORIO_PROCEDIMENTOS_CIRURGIAO_SOCIO: 89,
    RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO: 92,
    RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO_RESUMO: 93,
    RELATORIO_AGENDA_CENTRAL_RESERVA: 88,
    RELATORIO_RESUMO_FATURAMENTO_ESTABELECIMENTO_SAUDE: 91,
    RELATORIO_RESUMO_FATURAMENTO_OPERADORA_SAUDE: 90,
    RELATORIO_SINTETICO_PRODUCAO_DIGITACAO: 141,
    RELATORIO_SOCIO: 27,
    RELATORIO_SOCIO_CONJUGE: 100,
    RELATORIO_SOCIO_DEPENDENTE: 101,
    RELATORIO_SOCIO_EMPRESA: 103,
    RELATORIO_SOCIO_ENDERECO: 102,
    RELATORIO_SOCIO_GERAL: 99,
    RELATORIO_SOCIO_GRUPO: 104,
    RELATORIO_SOCIO_HIERARQUIZACAO: 105,
    RELATORIO_DMS: 159,
    RELATORIO_DE_CONTAS_A_RECEBER: 160,
    RELATORIO_GUIAS_CANCELADAS: 161,
    RELATORIO_GUIA_ESTABELECIMENTO_SAUDE_ANALITICO: 162,
    RELATORIO_EXTRATO_BANCARIO_DIARIO: 163,
    RELATORIO_DE_CONTAS_A_PAGAR_SINTETICO: 167,
    RELATORIO_MOVIMENTACOES_BANCARIAS: 164,
    RELATORIO_FLUXO_CAIXA: 165,
    RELATORIO_DE_CONTAS_PAGAR_RECEBER: 167,

}

export { ACOES, MODULO, RETENCOES, SERVICE, TIPO_ARQUIVO, GUIA_STATUS, MODULOS_SISTEMA }