import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../../components/DataGridAlternativa'
import { getApi, getUsuario } from '../../../../../util/Configuracoes'
import { validaCadastro, get, excluir } from '../../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Validar } from '../../../../../components/Acoes'
import { mostrarToast } from '../../../../../components/Toasts'
import { mensagens } from '../../../../../util/Utilitarios'
import { ACOES } from '../../../../../util/Enums'

const Consulta = (props) => {
    const [data, setData] = useState([])
    const target = useRef(null)

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-empresa',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'socio',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'socio.id'
                        }
                    },
                    {
                        Header: 'Data',
                        accessor: 'data',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: 'data',
                            // datepicker: true
                            date: true
                        }
                    },
                    {
                        Header: 'Referência',
                        accessor: 'periodo_gozo',
                        ordenamento: {
                            tipo: 'numero',
                        },
                        filtro: {
                            tipo: 'ano_gozo',
                            idColuna: 'ano'
                        }
                    }
                    ,
                    {
                        Header: 'Período',
                        accessor: 'periodo',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'periodo_marcacao',
                            idColuna: 'ferias_periodo.id'
                        }
                    },
                    {
                        Header: 'Hora inicial',
                        accessor: 'hora_inicial',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Hora final',
                        accessor: 'hora_final',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status_id',
                        visivel: false,
                        filtro: {
                            tipo: 'status_marcacao',
                            idColuna: 'status_id',
                        }
                    },
                ],
            },
        ],
        []
    )


    const exclusao = propsAcao => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                excluir('/administracao/ferias/marcacao', propsAcao.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        console.log('erro ', e)
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const maisAcoesObj = [

        (propsAcao) => <Visualizar to={'/administracao/ferias/marcacao/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/administracao/ferias/marcacao/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={'/administracao/ferias/marcacao/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => propsAcao.row['status_id'] === 1 ? <Validar to={'/administracao/ferias/marcacao/consulta'} texto={'Confirmar Marcação'} action={() => validaCadastro('/administracao/ferias', '/confirmaMarcacao', { id: propsAcao.id },
            _ => {
                target.current.atualizar();
                getApi(setData, '/controleAcesso/usuario')
                mostrarToast('sucesso', mensagens['sucesso'])
            })} /> : <></>
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/administracao/ferias/marcacao' status={'status'} colunaStatus={'socio'} idStatus={'status_id'}
                    acoesObj={maisAcoesObj}
                    tituloLista={'Lista Marcação'} caminhoConsultaFiltros='/administracao/ferias/marcacaoPaginacao' urlCadastro='/administracao/ferias/marcacao/cadastro'
                    modulo={props.modulo} titulo={'Consulta de Marcação'} icone={'icon-Yacht'} />
            </div>
        </>
    )
}

export default Consulta