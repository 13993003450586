import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import {
    InputTipoRelatorio, InputSimNao, InputOperadoraSaude, InputSocioAtivos,
    InputEmpresa, InputAcomodacao, InputTipoAtendimento, InputTipoAtendimentoEquipe,
    InputTipoAtendimentoTiss, InputTipoGuia, InputTipoExecutante, InputEstabelecimentoSaude, InputProcedimentoCodigo, InputLoteGuia, InputGuiaStatusRelatorio,
    InputStatusEnvio, InputLoteStatus, InputCirurgiaoCombo, InputContratoFiltro, InputContratoFiltroParticular,
    InputTipoRelatorioPersonalizado
} from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputMonthPicker, InputTimePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios';
import { mostrarToast } from '../../../components/Toasts';
import { InputConselhoProfissional, InputUsuario } from '../../../components/InputsAutoCompleteV2';
import { TableVirtuoso } from 'react-virtuoso';
import { RelatorioEstabelecimentoAnalitico, RelatorioGuiaEstabelecimento, RelatorioGuiaOperadoraSaude, RelatorioGuiaSocioCoopanest, RelatorioGuiaSocioEquipe } from '../../../components/RelatorioEspecifico/RelatorioAgrupado';
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'
import { MODULOS_SISTEMA } from '../../../util/Enums';


export default props => {
    const startOfMonth = moment().clone().format('DD/MM/YYYY')
    const endOfMonth = moment().clone().format('DD/MM/YYYY')
    const actualMonth = moment().clone().format('MM/YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Guia')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    const ref = useRef(null)
    const [ordenamento, setOrdenamento] = useState([])

    const tipoRelatorio = [
        { label: 'Agrupado por Estabelecimento de Saúde', value: MODULOS_SISTEMA.RELATORIO_AGRUPADO_ESTABELECIMENTO_SAUDE },
        { label: 'Agrupado por Estab. de Saúde - Análitico', value: MODULOS_SISTEMA.RELATORIO_GUIA_ESTABELECIMENTO_SAUDE_ANALITICO, },
        { label: 'Agrupado por Operadora de Saúde', value: MODULOS_SISTEMA.RELATORIO_AGRUPADO_OPERADORA_SAUDE },
        { label: 'Agrupado por Sócio - EQUIPE', value: MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_EQUIPE },
        { label: 'Agrupado por Sócio - COOPANEST-GO', value: MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_COOPANEST_GO },
        { label: 'Guia Geral', value: MODULOS_SISTEMA.RELATORIO_GUIA_GERAL },
        { label: 'Guia Para Correção', value: MODULOS_SISTEMA.RELATORIO_GUIA_GERAL_CORRECAO },
        { label: 'Guia/NFSe', value: MODULOS_SISTEMA.RELATORIO_GUIA_NFSE },
        { label: 'Guias Canceladas', value: MODULOS_SISTEMA.RELATORIO_GUIAS_CANCELADAS },
    ]

    const [validacao, setValidacao] = useState(Yup.object().shape({
        // referencia: Yup.string().nullable()
        //     .when('data_procedimento_inicial', {
        //         is: (data_inicial_procedimento) => !data_inicial_procedimento || data_inicial_procedimento.length === 0,
        //         then: Yup.string().required(),
        //         otherwise: Yup.string()
        //     }),
        // data_procedimento_inicial: Yup.string()
        //     .when('referencia', {
        //         is: (referencia) => !referencia || referencia.length === 0,
        //         then: Yup.string().required(),
        //         otherwise: Yup.string()
        //     }),
        // data_procedimento_final: Yup.string()
        //     .when('email', {
        //         is: (email) => !email || email.length === 0,
        //         then: Yup.string().required(),
        //         otherwise: Yup.string()
        //     })
    }, ['referencia', 'data_procedimento_inicial', 'data_procedimento_final']))

    const filtrosPadrao = propsFormik => [
        <InputMonthPicker label="Competência" name="competencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
        <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
        <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
            setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
            setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
        <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
            setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
        <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
            setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
        <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
        <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputLoteStatus label="Lote Status" placeholder="Lote Status" name="lote_status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputStatusEnvio label="Lote Status Envio" placeholder="Lote Status Envio" name="lote_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputGuiaStatusRelatorio multiplo label="Guia Status" name="status" placeholder="Guia Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputStatusEnvio label="Guia Status Envio" placeholder="Guia Status Envio" name="guia_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
            setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
            setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
    ]

    const calculoAgrupamento = (dadosAgr, itemColuna, arrayConfigCol) => {
        const arrayPf = dadosAgr.filter(item => item.guia_tipo_executante_id === 1 && item.valor_total);
        const arrayPj = dadosAgr.filter(item => item.guia_tipo_executante_id === 2 && item.valor_total);

        const pf = arrayPf.length > 0 ? arrayPf.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)) : 0;
        const pj = arrayPj.length > 0 ? arrayPj.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)) : 0;

        return (<>

            {arrayConfigCol[0].filter(x => x.coluna === 'valor_total_formatado' && x.visivel).length > 0 ?
                <tr>
                    <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                        <table>
                            <tr className={'tr-data text-right bold bt-1'}>
                                <td width={'90%'} className={'td-data pr-0'}><span>Valor Total PF: </span></td>
                                <td className={'td-data'} width={'10%'} >{formatarMoeda(pf)}</td></tr>
                            <tr className={'tr-data text-right bold'}>
                                <td width={'90%'} className={'td-data pr-0'}><span>Valor Total PJ: </span></td>
                                <td className={'td-data'} width={'10%'} >{formatarMoeda(pj)}</td></tr>
                            <tr className={'tr-data text-right bold'}>
                                <td width={'90%'} className={'td-data pr-0'}><span>Valor Total PF/PJ: </span></td>
                                <td className={'td-data'} width={'10%'}>{formatarMoeda(parseFloat(pf) + parseFloat(pj))}</td></tr>

                        </table>
                    </td>
                </tr >
                : <></>}
        </>)
    }

    const calculoAgrupamentoSocio = (dadosAgr, itemColuna, arrayConfigCol) => {
        const array = dadosAgr.filter(item => item.valor_total);

        const valor_total = array.length > 0 ? array.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)) : 0;

        return (<>
            {arrayConfigCol[0].filter(x => x.coluna === 'valor_total_formatado' && x.visivel).length > 0 ?
                <tr>
                    <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                        <table>
                            <tr className={'tr-data text-right bold'}>
                                <td width={'90%'} className={'td-data pr-0'}><span>Valor Total</span></td>
                                <td className={'td-data'} width={'10%'}>{formatarMoeda(parseFloat(valor_total))}</td></tr>
                        </table>
                    </td>
                </tr >
                : <></>}
        </>)
    }

    const dadosAdicionais = (dadosAgr, itemColuna, arrayConfigCol) => {
        return (<>
            <tr><td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0} className={'td-data pr-0 '}></td></tr>

            <tr>
                <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                    <table>
                        <tr class="group-title color-gray-relatorio" bgcolor='black'>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Procedimento</td>
                            <td class="bold coluna color-gray-coluna" width={'45%'}>Descrição</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Porte</td>
                            <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Porte(R$)</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>ACM(%)</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Perc.(%)</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Red.Acre.(%)</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Urg.(%)</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Qtde</td>
                            <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Total(R$)</td>
                        </tr>
                        {
                            dadosAgr[0].procedimentos && dadosAgr[0].procedimentos.map(item => {
                                return (
                                    <>
                                        <tr class="tr-data">
                                            <td class="td-data ">{item.codigo}</td>
                                            <td class="td-data ">{item.procedimento}</td>
                                            <td class="td-data ">{item.porte}</td>
                                            <td class="td-data text-right ">{formatarMoeda(item.valor_unitario)}</td>
                                            <td class="td-data ">{item.acomodacao}</td>
                                            <td class="td-data ">{item.percentual}</td>
                                            <td class="td-data ">{item.reducao_acrescimo}</td>
                                            <td class="td-data ">{item.urgencia === true ? item.urgencia_porcentagem + '%' : ''}</td>
                                            <td class="td-data ">{item.quantidade}</td>
                                            <td class="td-data text-right">{formatarMoeda(item.valor_total)}</td>
                                        </tr>
                                    </>)
                            })
                        }
                    </table>
                </td>
            </tr >
            <tr>
                <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                    <table>
                        <tr><td className={'td-data pr-0'}></td></tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Valor Total Guia: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(dadosAgr[0].valor_total || 0)}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr><td colspan={arrayConfigCol[0].filter(x => x.visivel).length} className={'td-data pr-0'}></td></tr>
            <tr>
                <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                    <table>
                        {
                            dadosAgr[0].executantes_externos &&
                            <>
                                <tr class=""><td class="titulo-agrupamento bold  mt-3  pt-2 pb-2">Executante Cooperativa</td></tr>
                                <tr class="group-title">
                                    <td class="bold coluna color-gray-coluna">Sócio</td>
                                    <td class="bold coluna color-gray-coluna">Tipo Pessoa</td>
                                    <td class="bold coluna color-gray-coluna">Empresa</td>
                                </tr>
                                {
                                    dadosAgr[0].executantes_externos.map(item => {
                                        return (
                                            <>
                                                <tr class="tr-data">

                                                    <td class="td-data"> {item.socio}</td>
                                                    <td class="td-data">{item.tipo_pessoa}</td>
                                                    <td class="td-data">{item.empresa}</td>
                                                </tr>
                                            </>)
                                    })
                                }
                            </>
                        }
                    </table>
                </td>
            </tr>
            <tr>
                <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                    <table>
                        {
                            dadosAgr[0].executantes_internos &&
                            <>
                                <tr class=""><td colspan="11" class="titulo-agrupamento bold  mt-3  pt-2 pb-2">Executante Equipe</td></tr>
                                <tr class="group-title mb-1">
                                    <td class="bold coluna color-gray-coluna">Data</td>
                                    <td class="bold coluna color-gray-coluna">Hora(Ini-Fin)</td>
                                    <td class="bold coluna color-gray-coluna">Sócio</td>
                                    <td class="bold coluna color-gray-coluna">Tipo Atendimento</td>
                                    <td class="bold coluna color-gray-coluna">Empresa</td>
                                </tr>
                                {
                                    dadosAgr[0].executantes_internos.map(item => {
                                        return (
                                            <>
                                                <tr class="tr-data">
                                                    <td class="td-data">{'30/03/2022'}</td>
                                                    <td class="td-data">{item.hora_inicial + ' - ' + item.hora_final}</td>
                                                    <td class="td-data">{item.socio}</td>
                                                    <td class="td-data">{item.tipo_atendimento}</td>
                                                    <td class="td-data">{item.empresa}</td>
                                                </tr>
                                            </>)
                                    })
                                }
                            </>
                        }
                    </table>
                </td>
            </tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 bt-guia-correcao'}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>

        </>
        )
    }

    const calculoValorTotal = (dadosAgr, arrayConfigCol) => {
        const valorTotal = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
        return (dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.length > 0 && arrayConfigCol[0].filter(x => x.coluna === 'valor_total_formatado' && x.visivel).length > 0 ? <>
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                    <td colspan={1} className={'td-data pr-0'}><span>Valor Total: </span><span className={'ml-2'}>{formatarMoeda(valorTotal)}</span></td>
                </tr>
            </table>
        </> : <></>)
    }

    const calculoValorTotalGeral = (dadosAgr, arrayConfigCol) => {
        const valorTotal = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
        return (
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                    <td colspan={1} className={'td-data pr-0'}><span>Valor Total: </span><span className={'ml-2'}>{formatarMoeda(valorTotal)}</span></td>
                </tr>
            </table>)
    }

    const dadosAdicionaisGuiaNfse = (dadosAgr, itemColuna, arrayConfigCol) => {
        return (<>
            <tr><td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0} className={'td-data pr-0 '}></td></tr>

            <tr>
                <td colspan={arrayConfigCol[0].filter(x => x.visivel).length}>
                    <table>
                        <tr class="group-title color-gray-relatorio" bgcolor='black'>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Número</td>
                            <td class="bold coluna color-gray-coluna" width={'5%'}>Data Emissão</td>
                            <td class="bold coluna color-gray-coluna" width={'35%'}>Tomador/Cliente</td>
                            <td class="bold coluna color-gray-coluna " width={'30%'}>Prestador/Empresa</td>
                            <td class="bold coluna color-gray-coluna text-right" width={'13%'}>Valor Nota (R$)</td>
                            <td class="bold coluna color-gray-coluna text-right" width={'12%'}>Valor Tributo (R$)</td>

                        </tr>
                        {
                            dadosAgr[0].nota_fiscal && dadosAgr[0].nota_fiscal.map(item => {
                                return (
                                    <>
                                        <tr class="tr-data">
                                            <td class="td-data ">{item.numero}</td>
                                            <td class="td-data ">{item.data_emissao}</td>
                                            <td class="td-data ">{item.tomador}</td>
                                            <td class="td-data  ">{item.prestador}</td>
                                            <td class="td-data text-right">{formatarMoeda(item.valor_nota)}</td>
                                            <td class="td-data text-right">{formatarMoeda(item.valor_tributo)}</td>
                                        </tr>
                                    </>)
                            })
                        }
                    </table>
                </td>
            </tr >
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 bt-guia-correcao'}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>
            <tr><td colspan={itemColuna.colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0 '}></td></tr>

        </>
        )
    }


    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Agrupado por Estabelecimento de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_AGRUPADO_ESTABELECIMENTO_SAUDE,
            url: '/relatorio/guiaEstabelecimentoSaude',
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            aposAgrupamento: calculoAgrupamento,
            aposRelatorio: calculoValorTotal,
            repetirColunas: true,
            agrupado: true, //usado para o csv 
            ordemColunas: ['estabelecimento_saude'],
            colunasCsv: [
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false, ordenar: 'operadora_saude_nome' } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false, ordenar: 'operadora_saude_nome' } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            agrupamento: [{ coluna: 'estabelecimento_saude', descricao: 'estabelecimento_saude' }],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento ', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaEstabelecimento array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={true} />,
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaEstabelecimento array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={false} />,
        }, {
            titulo: 'Agrupado por Operadora de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_AGRUPADO_OPERADORA_SAUDE,
            url: '/relatorio/guiaOperadoraSaude',
            campos: filtrosPadrao(propsFormik),
            aposAgrupamento: calculoAgrupamento,
            aposRelatorio: calculoValorTotal,
            classeTr: 'linha-bottom-relatorio',
            repetirColunas: true,
            agrupado: true, //usado para o csv 
            ordemColunas: ['operadora_saude'],
            agrupamento: [{ coluna: 'operadora_saude', descricao: 'operadora_saude' }],
            colunasCsv: [
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false, ordenar: 'estabelecimento_saude_nome' } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }

            ],
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false, ordenar: 'estabelecimento_saude_nome' } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }

            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaOperadoraSaude array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={true} />,
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaOperadoraSaude array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={false} />,
        }, {
            titulo: 'Agrupado por Sócio - EQUIPE',
            tipo: MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_EQUIPE,
            url: '/relatorio/guiaSocioEquipe',
            ajustaEscala: true,
            virtuoso: true,
            // aposAgrupamento: calculoAgrupamentoSocio,
            // aposRelatorio: calculoValorTotalGeral,

            repetirColunas: true,
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
                <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
                <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputGuiaStatusRelatorio multiplo label="Status" name="status" placeholder="Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,

            ],
            classeTr: 'linha-bottom-relatorio',
            ordemColunas: ['nome_socio'],
            agrupamento: [{ coluna: 'nome_socio', descricao: 'nome_socio' }],
            agrupado: true, //usado para o csv 
            // numerarLinha: false,
            colunasCsv: [
                { label: 'Socio', width: '10%', name: 'nome_socio', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', width: '3%', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', width: '20%', name: 'nome_beneficiario', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', width: '5%', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', width: '10%', name: 'horario', visivel: true, width: '6%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Hora Executante', width: '3%', name: 'horario_executante', visivel: false, width: '6%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', width: '12%', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', width: '12%', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'guia_status', width: '8%', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', width: '6%', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', width: '10%', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false }, alinhamento: 'text-right' }

            ],
            colunas: [
                { label: '#', name: '#', width: '1%', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', width: '3%', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', width: '20%', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', width: '5%', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', width: '10%', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Hora Executante', width: '3%', name: 'horario_executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', width: '12%', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', width: '12%', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', width: '8%', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', width: '6%', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', width: '10%', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', width: '10%', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false }, alinhamento: 'text-right' }

            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaSocioEquipe array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={true} />,
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaSocioEquipe array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={false} />,
        }, {
            titulo: 'Agrupado por Sócio - COOPANEST-GO',
            tipo: MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_COOPANEST_GO,
            url: '/relatorio/guiaSocioCooperativa',
            aposAgrupamento: calculoAgrupamento,
            aposRelatorio: calculoValorTotal,
            repetirColunas: true,
            virtuoso: true,
            agrupado: true, //usado para o csv 
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
                <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
                <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
                <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputGuiaStatusRelatorio multiplo label="Status" name="status" placeholder="Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,

            ],
            classeTr: 'linha-bottom-relatorio',
            ordemColunas: ['nome_socio'],
            agrupamento: [{ coluna: 'nome', descricao: 'nome_socio' }],
            colunasCsv: [
                { label: 'Socio', name: 'nome_socio', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false }, alinhamento: 'text-right' }
            ],
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Hora Proced.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Executante', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false }, alinhamento: 'text-right' }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaSocioCoopanest array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={true} />,
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioGuiaSocioCoopanest array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={false} />,
        }, {
            titulo: 'Guia Geral',
            tipo: MODULOS_SISTEMA.RELATORIO_GUIA_GERAL,
            url: '/faturamento/relatorioguiageral',
            campos: [<InputMonthPicker label="Competência" name="competencia" type="text"
                setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
            <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
            <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
            <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputConselhoProfissional label="Conselho Profissional" placeholder="Conselho Profissional" name="conselho_profissional" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
            <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
                setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
            <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
                setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
            <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
            <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputLoteStatus label="Lote Status" placeholder="Lote Status" name="lote_status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputStatusEnvio label="Lote Status Envio" placeholder="Lote Status Envio" name="lote_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputGuiaStatusRelatorio multiplo label="Guia Status" name="status" placeholder="Guia Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputStatusEnvio label="Guia Status Envio" placeholder="Guia Status Envio" name="guia_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,],


            aposAgrupamento: dadosAdicionais,
            aposRelatorio: calculoValorTotalGeral,
            agrupamento: [{ coluna: 'guia_formatada', descricao: null }],
            ordenarGeral: true,
            repetirColunas: false,
            classeTr: 'linha-bottom-relatorio',
            agrupado: true,
            virtuoso: true,
            colunasCsv: [
                { label: 'Código', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'csv_acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Guia Atribuída pela operadora', name: 'guia_principal', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Senha', name: 'senha', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Carteira/Matrícula', name: 'carteira', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cartão Nacional de Saúde', name: 'numero_sus', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },

                { label: 'Código Proc', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Procedimento', name: 'procedimento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Porte', name: 'porte', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Porte(R$)	', name: 'valor_unitario_csv', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'ACM(%)', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Perc.(%)', name: 'percentual', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Red.Acre.(%)', name: 'reducao_acrescimo', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Urg.(%)', name: 'urgencia_porcentagem', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Qtde', name: 'quantidade', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Total(R$)', name: 'valor_total_csv', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },

                { label: 'Competência', name: 'competencia', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Conselho Cirurgião', name: 'numero_conselho_cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cirurgião', name: 'cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CRM Sócio', name: 'crm_anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },

                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            colunas: [
                { label: '#', name: '#', idxAgrupamento: true, visivel: true, alinhamento: 'text-left', classe: 'bold' },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Protocolo Envio', name: 'protocolo_envio', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Conselho Profissional', name: 'cro_id', name_coluna: 'conselho_profissional' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ]
        }, {
            titulo: 'Guia Geral Para Correção',
            tipo: MODULOS_SISTEMA.RELATORIO_GUIA_GERAL_CORRECAO,
            url: '/relatorio/guiacorrecao',
            campos: filtrosPadrao(propsFormik),
            aposAgrupamento: dadosAdicionais,
            aposRelatorio: calculoValorTotal,
            classeTr: 'linha-bottom-relatorio',
            agrupamento: [{ coluna: 'guia_formatada', descricao: null }],
            ordenarGeral: true,
            repetirColunas: false,
            agrupado: true,
            virtuoso: true,
            coluna_agrupar: 'procedimentos',
            colunasCsv: [
                { label: 'Código', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'csv_acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },

                { label: 'Código Proc', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Procedimento', name: 'procedimento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Porte', name: 'porte', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Porte(R$)	', name: 'valor_unitario_csv', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'ACM(%)', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Perc.(%)', name: 'percentual', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Red.Acre.(%)', name: 'reducao_acrescimo', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Urg.(%)', name: 'urgencia_porcentagem', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Qtde', name: 'quantidade', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Total(R$)', name: 'valor_total_csv', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Sócio COOPANEST', name: 'socio_externo', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Sócio EQUIPE', name: 'socio_interno', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },

                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }

            ],
            colunas: [
                { label: '#', name: '#', idxAgrupamento: true, visivel: true, alinhamento: 'text-left', classe: 'bold' },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód. Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'horario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Op. Saude', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'guia_status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Protocolo Envio', name: 'protocolo_envio', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }

            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acm.', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
        },
        {
            titulo: 'Agrupado por Estab. de Saúde - Análitico',
            tipo: MODULOS_SISTEMA.RELATORIO_GUIA_ESTABELECIMENTO_SAUDE_ANALITICO,
            url: '/relatorio/guiaEstabelecimentoSaudeAnalitico',
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            repetirColunas: true,
            agrupado: true,
            virtuoso: true,
            colunasCsv: [
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cod Procedimento', name: 'codigo_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, proced: true },
                { label: 'Procedimento', name: 'procedimento_descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Justificativa Status', name: 'justificativa_status', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },

            ],
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cod Procedimento', name: 'codigo_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, proced: true },
                { label: 'Procedimento', name: 'procedimento', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Justificativa Status', name: 'justificativa_status', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '15%' },
                { label: 'Valor', name: 'valor', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' },
            ],
            data: [],
            agrupamento: [{ coluna: 'estabelecimento_saude', descricao: 'estabelecimento_saude' }],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento ', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioEstabelecimentoAnalitico array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={true} />,
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => <RelatorioEstabelecimentoAnalitico array={dados} arrayConfigCol={arrayConfigCol} configOrdenamento={configOrdenamento} htmlHeader={htmlHeader} virtuoso={false} />,
        },
        {
            titulo: 'Guia/NFSE',
            tipo: MODULOS_SISTEMA.RELATORIO_GUIA_NFSE,
            url: '/faturamento/relatorioguianfse',
            campos: [<InputMonthPicker label="Competência" name="competencia" type="text"
                setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
            <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
            <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
            <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputConselhoProfissional label="Conselho Profissional" placeholder="Conselho Profissional" name="conselho_profissional" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
            <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
                setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
            <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
                setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
            <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            //<InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
            <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            //<InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            //<InputLoteStatus label="Lote Status" placeholder="Lote Status" name="lote_status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputStatusEnvio label="Lote Status Envio" placeholder="Lote Status Envio" name="lote_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputGuiaStatusRelatorio multiplo label="Guia Status" name="status" placeholder="Guia Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputStatusEnvio label="Guia Status Envio" placeholder="Guia Status Envio" name="guia_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
                setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
            <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,],


            aposAgrupamento: dadosAdicionaisGuiaNfse,
            // aposRelatorio: calculoValorTotalGeral,
            agrupamento: [{ coluna: 'guia_formatada', descricao: null }],
            ordenarGeral: true,
            repetirColunas: false,
            classeTr: 'linha-bottom-relatorio',
            agrupado: true,
            virtuoso: true,
            colunasCsv: [
                { label: 'Código', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'csv_acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Guia Atribuída pela operadora', name: 'guia_principal', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Senha', name: 'senha', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Carteira/Matrícula', name: 'carteira', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cartão Nacional de Saúde', name: 'numero_sus', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Total Guia', name: 'valor_total_guia_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },

                { label: 'Número NFSe', name: 'numero', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Data Emissão', name: 'data_emissao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tomador/Cliente', name: 'tomador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Prestador/Empresa', name: 'prestador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Nota (R$)', name: 'valor_nota_csv', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Tributo (R$)', name: 'valor_tributo_csv', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },

                { label: 'Competência', name: 'competencia', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                //{ label: 'Conselho Cirurgião', name: 'numero_conselho_cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cirurgião', name: 'cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CRM Sócio', name: 'crm_anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },

                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            colunas: [
                { label: '#', name: '#', idxAgrupamento: true, visivel: true, alinhamento: 'text-left', classe: 'bold' },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Protocolo Envio', name: 'protocolo_envio', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Total Guia R$', name: 'valor_total', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            // coluna_agrupar: 'nota_fiscal',

            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Status', name: 'guia.guia_status_id', name_coluna: 'status' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Conselho Profissional', name: 'cro_id', name_coluna: 'conselho_profissional' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ]
        },
        {
            titulo: 'Relatório de Guias Canceladas',
            tipo: MODULOS_SISTEMA.RELATORIO_GUIAS_CANCELADAS,
            url: '/faturamento/relatorioguiascanceladas',
            campos: [
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputConselhoProfissional label="Conselho Profissional" placeholder="Conselho Profissional" name="conselho_profissional" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
                <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
                <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
                <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                // <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                // <InputLoteStatus label="Lote Status" placeholder="Lote Status" name="lote_status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                // <InputStatusEnvio label="Lote Status Envio" placeholder="Lote Status Envio" name="lote_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                // <InputStatusEnvio label="Guia Status Envio" placeholder="Guia Status Envio" name="guia_status_envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputSimNao label="Digitalizados" placeholder="Digitalizados" name="digitalizado" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputUsuario label="Usuário Alteração" placeholder="Usuário Alteração" name="usuario_alteracao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputDatePicker label="Data Alteração Inicial" name="data_alteracao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Alteração Final" name="data_alteracao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_alteracao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                // <InputSimNao label="Baixadas?" placeholder="Baixadas?" name="baixada" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
            ],
            aposAgrupamento: dadosAdicionais,
            aposRelatorio: calculoValorTotalGeral,
            agrupamento: [{ coluna: 'guia_formatada', descricao: null }],
            ordenarGeral: true,
            repetirColunas: false,
            classeTr: 'linha-bottom-relatorio',
            agrupado: true,
            virtuoso: true,
            colunasCsv: [
                { label: 'Código', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'csv_acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Guia Atribuída pela operadora', name: 'guia_principal', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Senha', name: 'senha', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Carteira/Matrícula', name: 'carteira', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cartão Nacional de Saúde', name: 'numero_sus', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },

                { label: 'Código Proc', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Procedimento', name: 'procedimento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Porte', name: 'porte', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Porte(R$)	', name: 'valor_unitario_csv', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'ACM(%)', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Perc.(%)', name: 'percentual', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Red.Acre.(%)', name: 'reducao_acrescimo', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Urg.(%)', name: 'urgencia_porcentagem', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Qtde', name: 'quantidade', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Total(R$)', name: 'valor_total_csv', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },

                { label: 'Competência', name: 'competencia', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Conselho Cirurgião', name: 'numero_conselho_cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cirurgião', name: 'cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CRM Sócio', name: 'crm_anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'anestesista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },

                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            colunas: [
                { label: '#', name: '#', idxAgrupamento: true, visivel: true, alinhamento: 'text-left', classe: 'bold' },
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Empresa', name: 'empresa', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cód Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Acm.', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Hora Proc.', name: 'hora_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Op. de Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab. Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Protocolo Envio', name: 'protocolo_envio', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Lote', name: 'numero_lote', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                // { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Registro Inicial', name: 'data_registro', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro', name_coluna: 'data_registro_final' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Digitalizados', name: 'digitalizado', name_coluna: 'digitalizado' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Conselho Profissional', name: 'cro_id', name_coluna: 'conselho_profissional' },
                { label: 'Conferida', name: 'conferida', name_coluna: 'conferida' },
                // { label: 'Status Lote', name: 'lote_status', name_coluna: 'lote_status' },
                // { label: 'Status Envio Lote', name: 'lote_status_envio', name_coluna: 'lote_status_envio' },
                // { label: 'Status Envio Guia', name: 'guia_status_envio', name_coluna: 'guia_status_envio' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' },
                { label: 'Usuario Alteração', name: 'usuario_id_alteracao', name_coluna: 'usuario_alteracao' },
                { label: 'Data Alteração Inicial', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_inicial' },
                { label: 'Data Alteração Final', name: 'data_ultima_alteracao', name_coluna: 'data_alteracao_final' },
                // { label: 'Baixados?', name: 'baixada', name_coluna: 'baixada' },
            ]
        },
    ]

    const submit = (values, propsFormik) => {


        const { referencia, data_inicial_procedimento, data_final_procedimento } = values;

        if (relatorioSelecionado == MODULOS_SISTEMA.RELATORIO_GUIAS_CANCELADAS && !referencia && (!data_inicial_procedimento && !data_final_procedimento)) {
            mostrarToast('erro', 'Escolha ao menos um filtro! Referência ou Data Procedimento Inicial e Final');
        }

        else if (values && !values.competencia && !values.referencia && (!values.data_inicial_procedimento && !values.data_final_procedimento) && (!values.data_registro_inicial && !values.data_registro_final) && (!values.data_inicial_entrega && !values.data_final_entrega)) {

            mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else if (values && values.competencia && values.referencia && (values.data_inicial_procedimento || values.data_final_procedimento) && (values.data_registro_inicial || values.data_registro_final) && (values.data_inicial_entrega || values.data_final_entrega)) {

            mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else {
            setCarregando(true)
            let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

            for (let i = Object.keys(values).length; i > 0; i--)
                if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                    delete values[Object.keys(values)[i]]

            setShowModalFiltros(false)
            setValuesFiltros(values)
            setRelatorioAtual({ ...novoRelatorio })

            if (values.tipo_relatorio) {
                setFiltros(Object.keys(values).map((x, idx, array) => {

                    const valorAtual = values[x]
                    return (x !== 'tipo_relatorio'
                        && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                    {
                        coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                        filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                        name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                        value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                        dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                    })
                }).filter(x => x.coluna !== false && x.value))
            }
            else {
                setRelatorioAtual({})
                setCarregando(false)
                setDados([])
                setFiltros([])
            }
        }

    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })

        const isEmpty = Object.keys(cloneRelatorioAtual).length === 0
        !isEmpty &&
            consultarRelatorio(cloneRelatorioAtual.url, (array) => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{
                    numerarLinha: cloneRelatorioAtual.numerarLinha || true, colunas: cloneRelatorioAtual.colunas, virtuoso: cloneRelatorioAtual.virtuoso, coluna_agrupar: cloneRelatorioAtual?.coluna_agrupar || null, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, agrupado: cloneRelatorioAtual?.agrupado || false, aposRelatorio: cloneRelatorioAtual.aposRelatorio, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null,
                    relatorioEspecificoImpressao: !!cloneRelatorioAtual.relatorioEspecificoImpressao ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecificoImpressao(dados, arrayConfigCol, configOrdenamento) : null, virtuoso: !!cloneRelatorioAtual.virtuoso, ajustaEscala: !!cloneRelatorioAtual.ajustaEscala
                }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth, guia_complementar: value == 3 || value == 4 ? { value: 'false', label: 'Não' } : undefined })
        setRelatorioSelecionado(value?.value)
        setDados([])
    }
    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} agrupamento={relatorioAtual && relatorioAtual.agrupamento ? relatorioAtual.agrupamento : undefined}
            repetirColunas={relatorioAtual && relatorioAtual.repetirColunas} ref={ref} classePersonalizada={relatorioAtual && relatorioAtual.classePersonalizada} ordenarGeral={relatorioAtual && relatorioAtual.ordenarGeral} virtuoso={relatorioAtual.virtuoso} ajustaEscala={relatorioAtual.ajustaEscala}
            orientacao={(relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_GUIA_GERAL || relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_GUIA_GERAL_CORRECAO) ? 'A4 landscape' : 'portrait'} orientacaoPadrao={(relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_GUIA_GERAL || relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_GUIA_GERAL_CORRECAO) ? 'landscape' : 'portrait'}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}